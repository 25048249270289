import { useEffect, useState } from "react";
import CardImage from '@assets/CardImage.png'
import PointerImg from '@assets/PointerImg.png'
import { InputField, SmallButtonYellow, Checkbox } from 'component-library'
import { useTranslation } from "react-i18next";
import { useUser } from "@context/UserContext";
import { checkoutIndividual, getProducts, updateProfile } from '@api/API';
import { useNotification } from "@context/Notification/NotificationContext";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";


type BuySubscriptionProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const BuySubscription = ({ openModal, toggleModal, darkMode = false }: BuySubscriptionProps) => {
  const { t } = useTranslation()
  const customNavigate = useCustomNavigate();
  const { notify } = useNotification();
  const { isAuth, isLoading, user, fetchUserDetails } = useUser() as any;
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
    confirmEmail: '',
    dateBirth: '',
    subscribe: false,
    code: '',
    firstName: '',
    lastName: '',
    postal: '',
  })

  useEffect(() =>{
    if(!isLoading){      
      if(!isAuth){
        customNavigate('/inloggen')
        notify(t('loginRequired'), "error")
      }
    }
  }, [isAuth, isLoading])

  const [optIns, setOptIns] = useState<{
    marketing_emails_optin: boolean;
    terms_optin: boolean;
    age_optin: boolean;
    [key: string]: boolean;
  }>({
    marketing_emails_optin: false,
    terms_optin: false,
    age_optin: false
  })

  const handleOptin = (optin: string) => {
    setOptIns({
      ...optIns,
      [optin]: !optIns[optin]
    })
  }

  const [productId, setProductId] = useState(-1)

  const updateLoginDetails = (value: any, state: string) => {
    setLoginDetails({
      ...loginDetails,
      [state]: value
    })
  }

  useEffect(() => {
    if(isAuth){
      getAllProducts()
    }
  }, [isAuth])

  const getAllProducts = async() => {
    const response = await getProducts()
    if(response.data.success){
      if(response.data.data[0].participant === "INDIVIDUAL")
        setProductId(response.data.data[0].id)
    }
  }

  useEffect(() =>{
    if(user){
      updateUserDetails()
    }
  }, [user])

  const updateUserDetails = () => {
    isAuth && 
      setLoginDetails({
        ...loginDetails,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        postal: user.address?.zip || "",
        dateBirth: user.dateBirth,
        subscribe: user.optInPreferences?.emailMarketing || false
      })
  }

  const checkDisabled = () => {
    if (
        loginDetails.firstName.length == 0 ||
        loginDetails.lastName.length == 0 ||  
        !optIns.terms_optin ||
        !optIns.age_optin
      ) {
      return true
    } else {
      return false
    }
  } 

  const handleBuySubscription = async() => {
    setIsLoadingPage(true)
    const buySub = await handleUpdateAccount()
    if(buySub){
      const response = await checkoutIndividual(productId, true)
      if(response.data.success === true){
        window.location.href = response.data.data.redirect_url || ""
      }
    }
  }


  const handleUpdateAccount = async() => {
    const response = await updateProfile(loginDetails)
    if(response.status === 200){
      notify("Account gegevens zijn opgeslagen.", "success")
      return true
    } 
    else {
      notify("Er is een fout opgetreden.", "error")
      return false
    }
  } 
  return (
      <div className="w-full">
        <div className="flex mt-8 flex-col items-center ">
          <div className="flex justify-between items-start w-full">
            <div className="gap-y-5 flex flex-col flex-1">
              <div className="md:flex md:flex-row gap-x-6 mb-6">
                <div className="w-80">
                  <InputField
                    placeholder={t("firstName")}
                    value={loginDetails.firstName}
                    setState={() => {}}
                    type="text"
                    error={loginDetails.firstName.length > 0 && loginDetails.firstName.length < 2}
                    handleUpdate={updateLoginDetails}
                    itemKey={"firstName"}
                    darkMode={darkMode}
                    errorMsg="Verplicht veld."
                  />
                </div>
                <div className="w-80">
                  <InputField
                    placeholder={t("lastName")}
                    value={loginDetails.lastName}
                    setState={() => {}}
                    type="text"
                    error={loginDetails.lastName.length > 0 && loginDetails.lastName.length < 2}
                    handleUpdate={updateLoginDetails}
                    itemKey={"lastName"}
                    darkMode={darkMode}
                    errorMsg="Verplicht veld."
                  />
                </div>
              </div>
            </div>
          </div>
          <Checkbox
            value={optIns.age_optin}
            onClick={() => handleOptin('age_optin')}
            darkMode={darkMode}
            text={"Ik ben jonger dan 30 jaar (als je jonger bent dan 16 jaar heb je toestemming nodig van een ouder)."}
          />
          <Checkbox
            value={optIns.terms_optin}
            onClick={() => handleOptin('terms_optin')}
            darkMode={darkMode}
            text={t('termsConditions')}
          />
          <div className="flex flex-col gap-y-4 mt-8 w-60 mb-8">
            <SmallButtonYellow
              text={t("toOrder")}
              onClick={() => handleBuySubscription()}
              darkMode={darkMode}
              disabled={checkDisabled()}
              loading={isLoadingPage}
            />
          </div> 
        </div>
      </div>
  );
}  