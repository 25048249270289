import { useNavigate } from "react-router-dom";

type ItemHeaderSidebarProps = { 
  text: string;
  link: string;
  isActive: boolean; 
}

export const ItemHeaderSidebar = ({ text, link, isActive }: ItemHeaderSidebarProps) => {
  const navigate = useNavigate();

  return (
    <div 
      className={`
        border-b-[8px] flex items-center h-[80px] animate select-none px-4
        ${isActive ? 'border-main-color text-main-color font-bold' : 'border-black hover:text-main-color text-white cursor-pointer'} 
      `}
      onClick={() => navigate(link)}
    > 
      <div className="pt-2 text-center">
        {text}
      </div> 
    </div>
  );
};
