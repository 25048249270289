import { useLocation } from "react-router-dom";
import { ItemHeaderSidebar } from "./itemHeaderSidebar";
import { useState } from "react";

type HeaderSidebarProps = {
  items?: Array<{ text: string; link: string; isActive?: boolean }>;
};

export const HeaderSidebar = ({ items = [] }: HeaderSidebarProps) => {
  const location = useLocation(); 

  return (
    <div className="h-[80px] w-full bg-black text-white shadow-lg flex flex-row items-center z-[1000] sticky -top-1">
      <div className="flex-1 flex flex-row gap-x-6 px-4">
        {items.map((item, index) => {
          const isActive =
            item.isActive !== undefined
              ? item.isActive 
              : location.pathname === item.link; 

          return (
            <ItemHeaderSidebar
              key={index}
              text={item.text}
              link={item.link}
              isActive={isActive}
            />
          );
        })}
      </div>
      {/* <div className="cursor-pointer relative" onClick={toggleMessages}>
        <BellIcon className="w-6 h-6" />
        {getTotalUnreadMessages() && <div className="w-4 h-4 absolute -top-1 -right-1 bg-red-500 rounded-full"/>}
      </div> */}
    </div>
  );
};
