import { useEffect, useState } from 'react';
import individual from "@assets/individual-2024.png";
import { SmallButtonYellow } from "component-library";
import PointerImg from '@assets/PointerImg.png'
import { useUser } from "@context/UserContext";
import { RequestMBO } from '@components/mijn/RequestMBO';
import { RequestVO } from './RequestVO';
import { BuySubscription } from './BuySubscription';

interface MembershipActivationProps {
  userProfile: {
    email: string;
    firstName: string;
    lastName: string;
    dob: string;
    phone: string;
    subscribe: boolean;
  };
  setUserProfile?: any;
}

export const MembershipActivation: React.FC<MembershipActivationProps> = ({ userProfile, setUserProfile }:MembershipActivationProps) => {
  const { isAuth, isLoading, user } = useUser() as any;
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [loadingObj, setLoadingObj] = useState({
    update: false,
    pass: false,
    logout: false,
    optIn: false,
    subscription: true,
    profile: true,
    email: false
  });

  const handleCardClick = (cardId: string) => {
    setSelectedCard(cardId === selectedCard ? null : cardId);
  };

  useEffect(() => {
    if (selectedCard) {
      const isMobile = window.innerWidth <= 768;
      window.scrollBy({
        top: isMobile ? 1400 : 200,
        behavior: 'smooth',
      });
    }
  }, [selectedCard]);

  useEffect(() => {
    if (!isLoading) {
      if (isAuth) {
        updateUserDetails();
        setLoadingObj({ ...loadingObj, profile: false });
      }
    }
  }, [user, isLoading, isAuth]);

  const updateUserDetails = () => {
    setUserProfile({
      ...userProfile,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      dob: user.dateBirth,
      phone: user.phone,
      subscribe: user.optInPreferences?.emailMarketing
    });
  };

  return (
    <div className="w-full">
      <div className="w-full">
        <div className="flex flex-col items-center">
          <div className="flex justify-between items-start w-full">
            <div className="flex flex-col">
              <div className="font-bold font-formula tracking-wide text-2xl">Activeer (Kies?) je lidmaatschap</div>
              <p>Heb je geen activatiecode en ben je jonger dan 30 of student of docent, kies dan hieronder wat op jou van toepassing is om een code te krijgen.</p>
            </div>
          </div>
          <div className="lg:flex md:flex lg:justify-center md:justify-center gap-x-6">
            <div
              className={`lg:w-[70%] md:w-[70%] animate bg-slate-200 border rounded-lg p-4 flex flex-col relative cursor-pointer mt-16 mb-12 shadow-md hover:bg-sky-200 hover:border-main-color transform scale-95 animate ${selectedCard === 'mbo' ? 'border-main-color bg-sky-200' : ''}`}
              onClick={() => handleCardClick('mbo')}
            >
              <div className="relative self-center">
                <img src={individual} alt="card" className="min-w-24 h-[150px] rounded-lg self-center" />
                <div
                  className="bg-main-yellow aspect-square absolute -top-2 -left-3 p-2 text-black/80 font-bold font-formula tracking-wider flex items-center text-xl"
                  style={{ zIndex: 10 }}
                >
                  Gratis
                </div>
              </div>
              <div className="w-full text-black/80 mt-2">
                <div className="uppercase font-bold font-formula text-2xl mt-6 text-center tracking-wide">
                  Voor studenten en docenten
                </div>
                <div className="uppercase font-bold font-formula text-2xl text-center tracking-wide">
                  In het MBO
                </div>
              </div>
              <div className="self-center w-56 mt-6">
                <SmallButtonYellow text="Kies deze" onClick={() => handleCardClick('mbo')} />
              </div>
            </div>

            <div
              className={`lg:w-[90%] md:w-[90%] animate bg-slate-200 border rounded-lg p-4 flex flex-col relative cursor-pointer mt-12 shadow-md hover:bg-sky-200 hover:border-main-color ${selectedCard === 'under30' ? 'border-main-color bg-sky-200' : ''}`}
              onClick={() => handleCardClick('under30')}
            >
              <div className="relative self-center">
                <img src={individual} alt="card" className="min-w-24 h-[200px] rounded-lg self-center" />
                <div
                  className="bg-main-yellow aspect-square absolute -top-2 -left-3 p-3 text-black/80 font-bold font-formula tracking-wider flex items-center text-xl"
                  style={{ zIndex: 10 }}
                >
                  €1O.-
                </div>
              </div>
              <div className="w-full text-black/80 mt-2">
                <div className="uppercase font-bold text-2xl text-center tracking-wide">
                  <span className='font-formula'>Voor iedereen onder 3O</span><span className='font-caramel'> </span> <span className='font-formula'>jaar</span>
                </div>
                <div className="flex gap-x-2 mt-2">
                  <img src={PointerImg} className="w-4 h-4 mt-1" />
                  <span>Na het eerste jaar word je jaarlijks geïncasseerd voor € 17,5O per jaar.</span>
                </div>
                <div className="flex gap-x-2 mt-2">
                  <img src={PointerImg} className="w-4 h-4 mt-1" />
                  <span>Na het eerste jaar kun je maandelijks opzeggen.</span>
                </div>
              </div>
              <div className="self-center w-56 mt-6">
                <SmallButtonYellow text="Kies deze" onClick={() => handleCardClick('under30')} />
              </div>
            </div>

            <div
              className={`lg:w-[70%] md:w-[70%] animate bg-slate-200 border rounded-lg p-4 flex flex-col relative cursor-pointer mt-16 mb-12 shadow-md hover:bg-sky-200 hover:border-main-color transform scale-95 ${selectedCard === 'school' ? 'border-main-color bg-sky-200' : ''}`}
              onClick={() => handleCardClick('school')}
            >
              <div className="relative self-center">
                <img src={individual} alt="card" className="min-w-24 h-[150px] rounded-lg self-center" />
                <div
                  className="bg-main-yellow aspect-square absolute -top-2 -left-3 p-2 text-black/80 font-bold font-formula tracking-wider flex items-center text-xl"
                  style={{ zIndex: 10 }}
                >
                  Gratis
                </div>
              </div>
              <div className="w-full text-black/80 mt-2">
                <div className="uppercase font-bold font-formula mt-6 text-2xl text-center tracking-wide">
                  Voor studenten en docenten
                </div>
                <div className="uppercase font-bold font-formula text-2xl text-center tracking-wide">
                  In de middelbareschool
                </div>
              </div>
              <div className="self-center w-56 mt-6">
                <SmallButtonYellow text="Kies deze" onClick={() => handleCardClick('school')} />
              </div>
            </div>
          </div>

          {selectedCard === 'mbo' && 
            <RequestMBO 
              openModal={false} 
              toggleModal={function (): void {
              throw new Error('Function not implemented.');
              } } 
            />
          }

          {selectedCard === 'school' && 
            <RequestVO 
              openModal={false} 
              toggleModal={function (): void {
              throw new Error('Function not implemented.');
              } } 
            />
          }

          {selectedCard === 'under30' && 
            <BuySubscription
              openModal={false} 
              toggleModal={function (): void {
              throw new Error('Function not implemented.');
              } } 
            />
          }
        </div>
      </div>
    </div>
  );
};