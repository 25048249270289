import { useEffect, useState } from 'react';
import { SmallButtonYellow } from "component-library";
import { useUser } from "@context/UserContext";
import { CodeActivation } from '@components/mijn/CodeActivation';
import { MembershipActivation } from '@components/mijn/MembershipActivation';
import { CJP_card } from '@components/modal/CJP_card';
import { useCustomNavigate } from '@components/navigate/useCustomNavigate';
import { PrivateLayout } from '@components/layout/PrivateLayout';
import { useNotification } from '@context/Notification/NotificationContext';
import { activateSubscription } from '@components/api/API';
import { useTranslation } from 'react-i18next';

const ItemHeaderSidebar = [
  {
    text: "Overzicht",
    link: "/my-account",
    requiresSubscription: true, 
  },
  {
    text: "Lidmaatschap toevoegen",
    link: "/my-account/add-sub",
    requiresSubscription: false,
  },
];

export const AddSubscription = () => {
  const { isAuth, isLoading, user, activeSubscription, fetchUserDetails } = useUser() as any;
  const { t } = useTranslation();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const customNavigate = useCustomNavigate();
  const [activeSub, setActiveSub] = useState(false);  
  const [subscription, setSubscription] = useState({} as any);
  const { notify } = useNotification();
  const [parentRequired, setParentRequired] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [showMembershipActivation, setShowMembershipActivation] = useState(false); 
  const [loadingObj, setLoadingObj] = useState({
    subscription: true,
    profile: true,
    activation: false
  });

  const [userProfile, setUserProfile] = useState({
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    dateBirth: '',
    code: '',
    dob: '',
    subscribe: false,
    postal: '',
    parentEmail: '',
  });

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible)
    setFirstTime(false);
  };

  useEffect(() => {
    if (!isLoading) {
      if(isAuth) {
        updateUserDetails();
        handleLoadingObj("profile", false);
        getUserSubscription();
      } else {
        customNavigate("/inloggen")
      }
    }
  }, [user, isLoading, isAuth, activeSubscription]);

  const updateUserDetails = () => {
    setUserProfile({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      dateBirth: user.dateBirth,
      phone: user.phone,
      code: userProfile.code,
      dob: userProfile.dob,
      subscribe: userProfile.subscribe,
      postal: userProfile.postal,
      parentEmail: userProfile.parentEmail,
    });
  };

  const handleLoadingObj = (key: string, value: boolean) => {
    setLoadingObj(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const getUserSubscription = async () => {
    if(activeSubscription?.id){
      setSubscription(activeSubscription);
      setActiveSub(true);
    } else {
      setSubscription({} as any);
      setActiveSub(false);
    }
    handleLoadingObj("subscription", false);
  };

  const handleActivateAccount = async () => {
    handleLoadingObj("activation", true);
    try {
      const data: any = {
        dateOfBirth: userProfile.dateBirth,
        email: userProfile.email,
        isSubscriber: String(userProfile.subscribe),
        postalCode: userProfile.postal,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName
      };
      if (parentRequired) {
        data['parentEmail'] = userProfile.parentEmail;
      }
      const response = await activateSubscription(userProfile.code, data);
      if (response?.status === 200) {
        fetchUserDetails();
        setIsPopupVisible(true);
        setFirstTime(true);
      } else {
        notify(t('activationFailed'), "error");
        console.error('Error activating account:', response);
      }
    } catch (error) {
      console.error('Error during activation:', error);
      notify(t('activationFailed'), "error");
    } finally {
      handleLoadingObj("activation", false);
    }
  };

  useEffect(() => {
    if(userProfile?.dateBirth) {
      checkParentRequired()
    }
  }, [userProfile.dateBirth])

  const checkParentRequired = () => {
    const today = new Date();
    const [day, month, year] = userProfile.dateBirth.split('-').map(Number);
    const birthDate = new Date(year, month - 1, day);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setParentRequired(age < 16);
  };

  useEffect(() => {
    document.title = "CJP | Mijn CJP";
    return () => {
      document.title = "CJP";
    };
  }, []);

  const filteredHeaderItems = ItemHeaderSidebar.filter(
    (item) => !item.requiresSubscription || activeSubscription?.id
  );

  return (
    <PrivateLayout headerItems={filteredHeaderItems}>
      <div className='pb-8'>
        <CodeActivation 
          userProfile={userProfile} 
          setUserProfile={setUserProfile} 
          handleActivateAccount={handleActivateAccount}
          parentRequired={parentRequired}
          loadingObj={loadingObj}
        />
        <div className="flex justify-center mt-4">
          <div className="w-80 text-center mt-2 mb-6">
            <button
              onClick={() => setShowMembershipActivation(true)}
              className="text-gray-500 font-bold underline focus:outline-none"
            >
              Ik heb geen activatiecode
            </button>
          </div>
        </div>
        {showMembershipActivation && (
          <MembershipActivation userProfile={userProfile} setUserProfile={setUserProfile} />
        )}
      </div>

      {(isPopupVisible && activeSub) && (
        <div
          className="fixed inset-0 bg-black z-[9999] bg-opacity-50 flex flex-col justify-center items-center transition-all duration-300 ease-in-out"
          onClick={togglePopup}
          style={{
            animation: 'fadeIn 0.3s ease-in-out'
          }}
        >

          {firstTime &&
            <div className='text-white text-2xl font-bold font-formula tracking-[3px]'>
              Jouw CJP is geactiveerd!
            </div>
          }
          <div 
            className="transform transition-all duration-300 ease-in-out"
            style={{
              animation: 'slideUp 0.3s ease-in-out'
            }}
          >
            <CJP_card 
              loadingObj={loadingObj} 
              userProfile={userProfile} 
              activeSub={activeSub} 
              subscription={subscription}
              togglePopup={togglePopup}
            />
          </div>
        </div>
      )}
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          @keyframes slideUp {
            from { 
              opacity: 0;
              transform: translateY(20px);
            }
            to { 
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </PrivateLayout>
  );
};