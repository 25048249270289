import { FC, ReactNode } from 'react';

interface LoadingSkeletonProps {
  isLoading?: boolean;
  width?: string;
  height?: string;
  containerHeight?: string;
  topPadding?: string;
  children: ReactNode;
}

export const LoadingSkeleton: FC<LoadingSkeletonProps> = ({
  isLoading = false,
  width = 'w-80',
  height = 'h-[46px]',
  containerHeight = 'h-[52px]',
  topPadding = 'mt-[6px]',
  children
}) => {
  if (isLoading) {
    return (
      <div className={`${containerHeight} ${width}`}>
        <div className={`bg-gray-200 rounded animate-pulse ${topPadding} ${height} w-full`} />
      </div>
    );
  }

  return <>{children}</>;
}; 