import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getOrders } from '@components/api/API';
import { PrivateLayout } from '@components/layout/PrivateLayout';
import { LoadingSkeleton } from '@components/skeleton/LoadingSkeleton';
import { getDeals } from './getDeals';
import { DealsCard } from '@components/modal/DealsCard';

const headerItems = [
  {
    text: "Tickets",
    link: "/my-deals/tickets",
  },
  {
    text: "Vouchers",
    link: "/my-deals/vouchers",
  },
];

export const MyDeals: React.FC = () => {
  interface Deal {
    id: number;
    title: string;
    location: string;
    validity: string;
    purchasedDate: string;
    price: string;
    image: string;
    order_provider_type: string;
  }
  const [tickets, setTickets] = useState<Deal[]>([]);
  const [vouchers, setVouchers] = useState<Deal[]>([]);
  const [notification, setNotification] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = (dealId: number) => {
    const context = location.pathname === "/my-deals/tickets" ? "tickets" : "vouchers";
    navigate(`/my-deals/tickets/${dealId}?context=${context}`);
  };

  const fetchDeals = async (inputData: any) => {
    let data = inputData;
    try {
      const formattedDeals = data.map((deal: any) => ({
        id: deal.id,
        title: deal.product?.name,
        location:
          typeof deal.product?.location === "string"
            ? deal.product.location
            : deal.product.location?.coordinates
            ? `${deal.product.location.coordinates[0]}, ${deal.product.location.coordinates[1]}`
            : "Unknown location",
        validity: deal.product?.status === "published" ? "Always" : "Not available",
        purchasedDate: new Date(deal.created_at).toLocaleDateString("nl-NL"),
        price: `€${deal.lines[0]?.price}`,
        image: deal.product?.images?.desktop[0] || "",
        order_provider_type: deal.order_provider_type,
      }));

      const tickets = formattedDeals.filter((deal: any) => deal.order_provider_type === "ticket");
      const vouchers = formattedDeals.filter((deal: any) => deal.order_provider_type === "voucher");

      setTickets(tickets);
      setVouchers(vouchers);
    } catch (error) {
      console.error("Error formatting deals", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleGetAllOrders = async () => {
  //   try {
  //     const res = await getOrders();
  //     if (res && res.status === 200) {
  //       fetchDeals(res.data);
  //     } else {
  //       fetchDeals([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching orders:", error);
  //     fetchDeals([]);
  //   }
  // };

  //for dev-docker:
  const handleGetAllOrders = async () => {
    try {
      let res;
      if (process.env.NODE_ENV === "development") {
        res = { status: 200, data: getDeals() }; 
      } else {
        res = await getOrders();
      }

      if (res && res.status === 200) {
        fetchDeals(res.data);
      } else {
        fetchDeals([]);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      fetchDeals([]);
    }
  };

  useEffect(() => {
    handleGetAllOrders();
  }, []);

  const currentDeals = location.pathname.includes("/tickets")
    ? tickets
    : location.pathname.includes("/vouchers")
    ? vouchers
    : [];

  useEffect(() => {
    document.title = "CJP | Mijn bestellingen";
    return () => {
      document.title = "CJP";
    };
  }, []);

  return (
    <PrivateLayout headerItems={headerItems}>
      <div className="font-bold font-formula pt-2 text-2xl tracking-wide">
        Mijn bestellingen
      </div>
      {notification && (
        <div className="fixed top-14 z-[9999] right-4 md:right-8 md:w-1/3 w-1/2 p-4 text-white bg-green-600 rounded-md">
          {notification}
        </div>
      )}
      <div className="grid mt-5 grid-cols-1 md:grid-cols-2 gap-6">
        {loading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <LoadingSkeleton 
              key={index} 
              isLoading={true}
              width="w-full"
              height="h-60 md:h-36"
              containerHeight="h-60 md:h-36"
              topPadding="mt-0"
            >
              <div />
            </LoadingSkeleton>
          ))
        ) : currentDeals.length > 0 ? (
          currentDeals.map((deal) => (
            <DealsCard
              id={deal.id}
              key={deal.id}
              title={deal.title}
              purchasedDate={deal.purchasedDate}
              image={deal.image}
              onClick={() => onClick(deal.id)}
              buttonText="Open jouw ticket"
            />
          ))
        ) : (
          <div className="text-gray-500 text-center col-span-full">
            Geen deals beschikbaar
          </div>
        )}
      </div>
    </PrivateLayout>
  );
};