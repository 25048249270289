import { useEffect, useState } from "react";
import { InputField, SmallButtonYellow } from 'component-library';
import { EmailCheck } from '@regex/EmailCheck';
import { useTranslation } from "react-i18next";
import { getSchoolByEmail, registerUser } from "@api/API";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { useNotification } from "@context/Notification/NotificationContext";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

type RequestMBOModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
};

export const RequestMBO = ({
  openModal,
  toggleModal,
  darkMode = false,
}: RequestMBOModalProps) => {
  const { t } = useTranslation();
  const customNavigate = useCustomNavigate();
  const { notify } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
    confirmEmail: '',
    dob: '',
    subscribe: false,
    code: '',
    firstName: '',
    lastName: '',
    postal: '',
    userType: 'student',
    schoolYear: 1,
  });
  const [userSchool, setUserSchool] = useState('');
  const [schoolUuid, setSchoolUuid] = useState('');
  const [requestSuccess, setRequestSuccess] = useState(false);

  useEffect(() => {
    if (loginDetails.email.length > 0 && EmailCheck(loginDetails.email)) {
      getSchool();
    }
  }, [loginDetails.email]);

  const updateLoginDetails = (value: any, state: string) => {
    setLoginDetails({
      ...loginDetails,
      [state]: value,
    });
  };

  const getSchool = async () => {
    let email = extractDomainFromEmail(loginDetails.email);

    const response = await getSchoolByEmail(email);
    if (response.status === 200) {
      setUserSchool(response.data.data.schoolName);
      setSchoolUuid(response.data.data.schoolUuid);
    } else {
      setUserSchool('');
      setSchoolUuid('');
      console.log('error', response);
    }
  };

  const extractDomainFromEmail = (email: string): string => {
    const atIndex = email.indexOf('@');
    if (atIndex > 0) {
      return email.substring(atIndex + 1);
    } else {
      return '';
    }
  };

  const checkDisabled = () => {
    if (loginDetails.userType === 'student') {
      if (
        !isLoading &&
        userSchool.length > 1 &&
        loginDetails.email.length > 0 &&
        EmailCheck(loginDetails.email) &&
        loginDetails.schoolYear > 0 &&
        loginDetails.firstName.length > 0 &&  
        loginDetails.lastName.length > 0      
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        !isLoading &&
        userSchool.length > 1 &&
        loginDetails.email.length > 0 &&
        EmailCheck(loginDetails.email) &&
        loginDetails.firstName.length > 0 &&  
        loginDetails.lastName.length > 0      
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleRequestCode = async () => {
    const data = {
      email: loginDetails.email,
      schoolYear: loginDetails.schoolYear,
      firstName: loginDetails.firstName,
      lastName: loginDetails.lastName,
    };
    try {
      setIsLoading(true);
      const response = await registerUser(schoolUuid, data, loginDetails.userType);
      if (response.data.success) {
        setRequestSuccess(true);
        notify(t('requestCodeSuccess'), "success");
        customNavigate('/lidmaatschap/activeren');
      } else {
        notify(t('notifyError'), "error");
        setIsLoading(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const parseClassYear = (inputValue: string) => {
    const year = parseInt(inputValue.slice(-1), 10);
    let data = { ...loginDetails };

    if (!isNaN(year) && year >= 1 && year <= 6) {
      data.schoolYear = year;
    } else {
      data.schoolYear = 1;
    }

    setLoginDetails(data);
  };

  return (
    <div className="w-full mt-8">
      <div className="">
        {!requestSuccess ? (
          <div className='gap-y-4 mt-2 mb-8'>
            <div className={`${darkMode ? "text-white" : "text-black"} flex gap-x-8`}>
              {t("iAmA")}
              <div className="flex gap-x-2 cursor-pointer" onClick={() => updateLoginDetails('student', 'userType')}>
                <div className={`w-6 h-6 overflow-hidden ${darkMode ? "bg-gray-700" : "bg-main-color"} ${loginDetails.userType === "student" ? "border-[5px]" : "border-[12px]"} animate rounded-full relative`}></div>
                <span className={`${loginDetails.userType === 'student' ? "underline decoration-2 decoration-main-yellow" : ""}`}>{t('student')}</span>
              </div>
              <div className="flex gap-x-2 cursor-pointer" onClick={() => updateLoginDetails('teacher', 'userType')}>
                <div className={`w-6 h-6 overflow-hidden ${darkMode ? "bg-gray-700" : "bg-main-color"} ${loginDetails.userType === "teacher" ? "border-[5px]" : "border-[12px]"} animate rounded-full relative`}></div>
                <span className={`${loginDetails.userType === 'teacher' ? "underline decoration-2 decoration-main-yellow" : ""}`}>{t('teacher')}</span>
              </div>
            </div>

            <div className="md:flex md:flex-row gap-x-6">
              <div className="w-80">
                <InputField
                  placeholder={t('schoolEmail')}
                  value={loginDetails.email}
                  setState={() => { }}
                  type="text"
                  error={loginDetails.email.length > 0 && !EmailCheck(loginDetails.email)}
                  handleUpdate={updateLoginDetails}
                  itemKey={'email'}
                  darkMode={darkMode}
                  errorMsg={t('emailError')}
                />
              </div>
              <div className="w-80">
                <InputField
                  placeholder={t('yourSchoolIs')}
                  value={userSchool}
                  setState={() => { }}
                  type="text"
                  error={false}
                  handleUpdate={updateLoginDetails}
                  itemKey={'code'}
                  darkMode={darkMode}
                  disabled={true}
                />
              </div>
            </div>

            {userSchool.length > 1 && (
              <div className="flex flex-row gap-x-6 mt-4">
                <div className="w-80">
                  <InputField
                    placeholder={t('firstName')}
                    value={loginDetails.firstName}
                    setState={() => { }}
                    type="text"
                    error={false}
                    handleUpdate={updateLoginDetails}
                    itemKey={'firstName'}
                    darkMode={darkMode}
                    errorMsg=""
                    disabled={false}
                  />
                </div>
                <div className="w-80">
                  <InputField
                    placeholder={t('lastName')}
                    value={loginDetails.lastName}
                    setState={() => { }}
                    type="text"
                    error={false}
                    handleUpdate={updateLoginDetails}
                    itemKey={'lastName'}
                    darkMode={darkMode}
                    errorMsg=""
                    disabled={false}
                  />
                </div>
              </div>
            )}
            {userSchool.length > 1 && (
              <div className="flex flex-row gap-x-6 mt-4">
                {loginDetails.userType === 'student' && (
                  <div className="w-80">
                    <InputField
                      placeholder={t('schoolYear')}
                      value={loginDetails.schoolYear.toString()}
                      setState={() => { }}
                      type="number"
                      error={false}
                      handleUpdate={(e: any) => parseClassYear(e)}
                      itemKey={'code'}
                      darkMode={darkMode}
                      errorMsg=""
                      disabled={false}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          ) : (
            <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'>
              Actievatiecode is aangevraagd!
              <br />
              <br />
              Check je inbox (en spam folder!) op je persoonlijke emailadres om de code te vinden en activeer je CJP pas.
            </div>
          )}    
        </div>
        <div className="w-full flex justify-center mt-6"> 
          <div className="w-64">
            <SmallButtonYellow
              text={t('requestCode')}
              onClick={() => handleRequestCode()}
              disabled={checkDisabled()}
              darkMode={darkMode}
              loading={isLoading}
            />
          </div>  
        </div>  
      </div>  
  );  
};
