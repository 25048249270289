import { checkCompromisedPassword } from "@components/api/API";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { CheckIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { EmailCheck } from "@regex/EmailCheck";
import { PasswordCheck } from "@regex/PasswordCheck";
import { InputField, SmallButtonYellow } from "component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type AccountPreferencesProps = {
  darkMode?: boolean;
  handleUpdatePass: any;
  resetPass: any;
  setResetPass: any;
  handleUpdateEmail: any;
  getUserProfile: any;
  loadingObj: any;
  handleCloseAccount: any;
  handleCancelSubscription: any;
  restitution: any;
  resetEmail: any;
  setResetEmail: any;
  subscriptions: any;
  handleLogout: () => void;
}

export const AccountPreferences = ({ 
  darkMode, 
  loadingObj,
  handleUpdatePass, 
  resetPass, 
  setResetPass,
  handleUpdateEmail,
  resetEmail,
  setResetEmail,
  handleCancelSubscription, 
  restitution,
  handleCloseAccount,
  subscriptions,
  handleLogout
}: AccountPreferencesProps) => {
  const { t } = useTranslation()
  const customNavigate = useCustomNavigate(); 
  const [areYouSure, setAreYouSure] = useState(false)
  const [confirmText, setConfirmText] = useState('') 
  const [removeSub, setRemoveSub] = useState(false)
  
  const [validations, setValidations] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    isLongEnough: false,
    isNotComporimised: false
  });

  const checkPassword = async() => {
    if(resetPass.password?.length < 7) return false
    let res = await checkCompromisedPassword(resetPass.password)
    if(res.status === 200) return true
    else return false
  }

  const validatePassword = async(password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isLongEnough = password.length >= 8;
    const isNotComporimised = await checkPassword()

    setValidations({
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      isLongEnough,
      isNotComporimised
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      validatePassword(resetPass.password);
    }, 250);
  
    return () => clearTimeout(timeoutId);
  }, [resetPass.password]);


  const updatePassChange = (value: any, state: string) => {
    setResetPass({
      ...resetPass,
      [state]: value
    })
  }

  const updateEmailChange = (value: any, state: string) => {
    setResetEmail({
      ...resetEmail,
      [state]: value
    })
  } 

  const isFutureDate = (isoDateString: string): boolean => { 
    const suppliedDate = new Date(isoDateString); 
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); 
    if (isNaN(suppliedDate.getTime())) {
      throw new Error('Invalid date format. Please use a valid ISO 8601 date string.');
    } 
    const suppliedDateOnly = new Date(suppliedDate);
    suppliedDateOnly.setHours(0, 0, 0, 0); 
    return suppliedDateOnly.getTime() > currentDate.getTime();
  };

  const findIndividualSubscription = () => { 
    let found = false
    subscriptions.forEach((sub: any) => { 
      if(sub.name.includes('Jongerenpas') || sub.name.includes('Individual')){
        if(isFutureDate(sub.endDate) && sub.autoRenew) found = true 
      }
    })
    if(restitution > 0) return false 
    return found
  }

  return(
    <div className="flex-1 flex flex-col mt-4">
      <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-left text-4xl font-formula tracking-wider pt-8`}>
        INSTELLINGEN
      </div>
      <hr className="my-4"/>
      <div className="flex-1">
        <div className="">
          <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-left text-3xl font-formula tracking-wider pb-2`}>
            WACHTWOORD AANPASSEN:
          </div>
          <InputField
            placeholder={"Nieuw wachtwoord"}
            value={resetPass.password || ""}
            setState={() => {}}
            type="password"
            error={resetPass.password.length > 0 && !PasswordCheck(resetPass.password)}
            handleUpdate={updatePassChange}
            itemKey={'password'}
            darkMode={darkMode}
            errorMsg={t("setPasswordError")}
          />
          {(resetPass.password.length > 0 && (!PasswordCheck(resetPass.password) || !validations.isNotComporimised)) &&
          <div className="text-sm">
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasUpperCase ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordUpperCase")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasLowerCase ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordLowerCase")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasNumber ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordNumber")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.isLongEnough ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordIsLongEnough")}
              </div>
            </div>
            {resetPass.password.length > 7 &&
              <div className="flex flex-row gap-x-2">
                <div>
                {validations.isNotComporimised ?
                  <CheckIcon className={`w-5 h-5 text-green-500`} />
                  :
                  <XMarkIcon className={`w-5 h-5 text-red-500`} />
                }
                </div>
                <div>
                  {"Wachtwoord is gevonden in database met gelekte wachtwoorden."}
                </div>
              </div>
            }
          </div>
        }
          <InputField
            placeholder={t("repeatPassword")}
            value={resetPass.repeatPassword || ""}
            setState={() => {}}
            type="password"
            error={resetPass.repeatPassword.length > 0 && (resetPass.repeatPassword !== resetPass.password )}
            handleUpdate={updatePassChange}
            itemKey={'repeatPassword'}
            darkMode={darkMode}
            errorMsg={t("repeatPasswordError")}
          />
          <div className="pt-8">
            <SmallButtonYellow
              text={"Aanpassen"}
              onClick={() => handleUpdatePass()}
              disabled={resetPass.password.length < 1 ||  (resetPass.repeatPassword !== resetPass.password ) || !validations.isNotComporimised}
              darkMode={darkMode}
              loading={loadingObj.pass}
            />
          </div>
        </div>
        <hr className="my-6" />
        <div className="">
          <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-left text-3xl font-formula tracking-wider pb-8`}>
            INSTELLEN TWEEFACTOR AUTHENTICATIE:
          </div>
          <SmallButtonYellow
            text={"Instellen"}
            onClick={() => customNavigate('/setup-2fa')}
            disabled={false}
            darkMode={darkMode}
          />
        </div>
        <hr className="my-8" />
        <div className="">
          <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-left text-3xl font-formula tracking-wider pb-2`}>
            E-MAILADRES AANPASSEN:
          </div>
          <InputField
            placeholder={"Nieuw e-mailadres"}
            value={resetEmail.email || ""}
            setState={() => {}}
            type="text"
            error={resetEmail.email.length > 0 && !EmailCheck(resetEmail.email)}
            handleUpdate={updateEmailChange}
            itemKey={'email'}
            darkMode={darkMode}
            errorMsg={t("emailError")}
          />
          <InputField
            placeholder={t("confirmEmail")}
            value={resetEmail.confirmEmail || ""}
            setState={() => {}}
            type="text"
            error={resetEmail.confirmEmail.length > 0 && (resetEmail.email !== resetEmail.confirmEmail )}
            handleUpdate={updateEmailChange}
            itemKey={'confirmEmail'}
            darkMode={darkMode}
            errorMsg={t("confirmEmailError")}
          />
          <div className="pt-8">
            <SmallButtonYellow
              text={"Aanpassen"}
              onClick={() => handleUpdateEmail()}
              disabled={resetEmail.email.length < 1 ||  (resetEmail.email !== resetEmail.confirmEmail )}
              darkMode={darkMode}
              loading={loadingObj.email}
            />
          </div>
        </div>
        <hr className="my-8" />
        
        <hr className="my-8" />
        
        <div className="">
          <div className={`font-bold ${darkMode ? "text-white" : "text-black"} text-left text-3xl font-formula tracking-wider pb-8`}>
            OPZEGGEN
          </div> 
          {restitution > 0 &&
            <div>
              <div>
                Abonnement is opgezegd. Er wordt <span className="font-bold">€{restitution.toFixed(2)}</span> teruggestort op je rekening.
              </div>
              <br/><hr/><br />
            </div>
          }
          <div>
            Let op: na het stoppen van je lidmaatschap een/of het verwijderen van je account heb je geen toegang meer tot de deals van CJP!
          </div>
          <div className="pt-8">
            {(removeSub && restitution === 0 ) ?
              <div>
                <div>
                  <div className="font-bold text-main-color text-lg">
                    Weet je zeker dat je je lidmaatschap wil opzeggen? 
                  </div>
                  <div>
                    Het stoppen van je lidmaatschap betekent dat je niet langer gebruik kan maken van de CJP deals en kortingen. Je account blijft wel bestaan.
                    <br/><br/>
                    Typ CJPWEGERMEE in het onderstaande veld om je lidmaatschap stop te zetten. Als je lidmaatschap minder dan 1 jaar oud is blijf je toegang houden tot de CJP deals en kortingen tot het eerste jaar is verlopen, in volgende jaren wordt het lidmaatschap naar rato vergoed.
                    <br/><br/> 
                  </div>
                  <div className="py-4">
                    <InputField
                      placeholder={"CJPWEGERMEE"}
                      value={confirmText}
                      setState={() => {}}
                      type="text" 
                      handleUpdate={setConfirmText}
                      itemKey={''}
                      darkMode={darkMode}
                    />
                  </div>
                </div>
                <div>
                  <SmallButtonYellow
                    text={"Lidmaatschap opzeggen"}
                    onClick={() => handleCancelSubscription()}
                    disabled={confirmText !== "CJPWEGERMEE"}
                    darkMode={darkMode}
                    loading={loadingObj.close}
                  />
                </div>
              </div>
              :
              <div className="pt-8">
                <SmallButtonYellow
                  text={"Abonnement opzeggen"}
                  onClick={() => setRemoveSub(true)}
                  disabled={!findIndividualSubscription()}
                  darkMode={darkMode}
                  loading={loadingObj.cancel}
                />
              </div>
            }
          </div>
          <div className="pt-8">
            {areYouSure ?
              <div>
                <div>
                  <div className="font-bold text-main-color text-lg">
                    Weet je zeker dat je je account wil sluiten? 
                  </div>
                  <div>
                    Het verwijderen van je account betekent dat je niet meer kunt inloggen en je lidmaatschap per direct wordt stopgezet. Je hebt dus geen recht meer op de deals van CJP. Ook je persoonsgegevens worden verwijderd.
                    <br/><br/>
                    Typ CJPWEGERMEE in het onderstaande veld om je account definitief te verwijderen.
                    <br/><br/>
                    Nadat je op verwijderen klikt word je automatisch uitgelogd.
                    <br/><br/>
                  </div>
                  <div className="py-4">
                    <InputField
                      placeholder={"CJPWEGERMEE"}
                      value={confirmText}
                      setState={() => {}}
                      type="text" 
                      handleUpdate={setConfirmText}
                      itemKey={''}
                      darkMode={darkMode}
                    />
                  </div>
                </div>
                <div>
                  <SmallButtonYellow
                    text={"Verwijderen"}
                    onClick={() => handleCloseAccount()}
                    disabled={confirmText !== "CJPWEGERMEE"}
                    darkMode={darkMode}
                    loading={loadingObj.close}
                  />
                </div>
              </div>
              :
              <div>
                <SmallButtonYellow
                  text={"Account sluiten"}
                  onClick={() => setAreYouSure(true)}
                  disabled={false}
                  darkMode={darkMode}
                  loading={loadingObj.close}
                />
              </div>
            }
          </div>
        </div>
      </div> 
      <hr className="mt-8"/>
      <div className="pt-8">
        <SmallButtonYellow
          text={"Uitloggen"}
          onClick={() => handleLogout()}
          disabled={false}
          darkMode={darkMode}
        />
      </div>
    </div>  
  )
}