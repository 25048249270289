import { useEffect, useState } from 'react';
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { Spinner, SmallButtonYellow  } from "component-library";
import logo from "@assets/logo.svg";
import passport_gorizontal from '@assets/passport_gorizontal.jpg';
import { useUser } from "@context/UserContext";
import { CJP_card } from '@components/modal/CJP_card';
import { useCustomNavigate } from '@components/navigate/useCustomNavigate';
import { PrivateLayout } from '@components/layout/PrivateLayout';
import { useNotification } from '@context/Notification/NotificationContext';
import { useTranslation } from 'react-i18next';

const ItemHeaderSidebar = [
  {
    text: "Overzicht",
    link: "/my-account"
  },
  {
    text: "Lidmaatschap toevoegen",
    link: "/my-account/add-sub"
  },
];

export const MyAccount = () => {
  const { isAuth, isLoading, user, activeSubscription, fetchUserDetails } = useUser() as any;
  const { t } = useTranslation();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const customNavigate = useCustomNavigate();
  const [activeSub, setActiveSub] = useState(false);  
  const [subscription, setSubscription] = useState({} as any);
  const { notify } = useNotification();
  const [firstTime, setFirstTime] = useState(false);
  const [loadingObj, setLoadingObj] = useState({
    subscription: true,
    profile: true,
    activation: false
  });

  const [userProfile, setUserProfile] = useState({
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    dateBirth: '',
    code: '',
    dob: '',
    subscribe: false,
    postal: '',
    parentEmail: '',
  });

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible)
    setFirstTime(false);
  };

  useEffect(() => {
    if (!isLoading) {
      if(isAuth) {
        updateUserDetails();
        handleLoadingObj("profile", false);
        getUserSubscription();
      } else {
        customNavigate("/inloggen")
      }
    }
  }, [user, isLoading, isAuth, activeSubscription]);

  useEffect(() => {
    if (!isLoading && isAuth && activeSubscription && !activeSubscription.id) {
      customNavigate("/my-account/add-sub");
    }
  }, [isLoading, isAuth, activeSubscription]);

  const updateUserDetails = () => {
    setUserProfile({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      dateBirth: user.dateBirth,
      phone: user.phone,
      code: userProfile.code,
      dob: userProfile.dob,
      subscribe: userProfile.subscribe,
      postal: userProfile.postal,
      parentEmail: userProfile.parentEmail,
    });
  };

  const copyToClipboard = async (text: string) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';
        textArea.style.left = '-9999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        const successful = document.execCommand('copy');
        document.body.removeChild(textArea);
        if (!successful) {
          throw new Error('Failed to copy text');
        }
      }
      return true;
    } catch (error) {
      console.error('Failed to copy text to clipboard:', error);
      return false;
    }
  };

  const handleLoadingObj = (key: string, value: boolean) => {
    setLoadingObj(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const getUserSubscription = async () => {
    if(activeSubscription?.id){
      setSubscription(activeSubscription);
      setActiveSub(true);
    } else {
      setSubscription({} as any);
      setActiveSub(false);
      customNavigate("/my-account/add-sub");
    }
    handleLoadingObj("subscription", false);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('nl-NL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const formatSubscriptionName = (name: string) => {
    return name?.replace(/\s*-\s*\d{4}\/\d{4}/, '');
  };

  useEffect(() => {
    document.title = "CJP | Mijn CJP";
    return () => {
      document.title = "CJP";
    };
  }, []);

  return (
    <PrivateLayout headerItems={ItemHeaderSidebar}>
      {/* <div className="flex-1">
        <div className="font-bold font-formula tracking-wide pt-2 text-2xl">Mijn CJP</div>
      </div> */}

      <div className="font-bold text-lg">
        {activeSub && (  
          <div className="relative mb-16 mt-8 pt-1 h-[350px] cursor-default max-w-full box-border">
            <div className="h-full w-full bg-[#03060c] hidden md:flex items-center justify-center overflow-hidden relative rounded-lg">
              <img
                src={passport_gorizontal}
                alt="card"
                className="rounded-lg shadow-md"
                style={{
                  width: '65%',
                  height: '100%',
                }}
              />
            </div>

            <div className="absolute top-0 left-8 right-8 flex justify-between items-center pt-6 px-6 w-full">
              <img
                src={logo}
                alt="logo"
                className="hidden md:block filter invert brightness-0"
              />
              <div className="text-white hidden md:block ml-20 hidden md:block uppercase font-thin text-center flex-1">{formatSubscriptionName(subscription?.tier || "") || ""}</div>
              <div className="mr-6 hidden md:block">
                <SmallButtonYellow
                  text="Toon digitale kaart"
                  onClick={togglePopup}
                  disabled={false}
                  loading={false}
                />
              </div>
            </div>

            <div className="font-bold text-white text-center justify-center absolute top-28 left-0 right-0 items-center">
              <span className='text-4xl hidden md:block'>{userProfile.firstName || ""} {userProfile.lastName || ""}</span> 
            </div>

            <div className="absolute bottom-0 mx-auto p-6 text-white text-sm font-normal bg-opacity-50">
              {loadingObj.profile ? (
                <div className="w-fit m-auto pt-12">
                  <Spinner />
                </div>
              ) : (
                <div className="text-white">
                  <div className='ml-8 hidden md:block'> 
                    {activeSubscription.school?.name && <div className='font-bold'> {activeSubscription.school?.name || ""}</div>}
                    <div>Geboortedatum {userProfile.dateBirth || ""}</div>
                    {activeSub && (
                      <div
                        className="flex flex-row hover:border-main-color border-white animate group w-fit cursor-pointer border-b"
                        onClick={() => copyToClipboard(subscription.code)}
                      >
                        CJP nr: {subscription.code}{" "}
                        <span>
                          <ClipboardDocumentIcon className="w-5 h-5 ml-2 animate text-gray-300 group-hover:text-main-color" />
                        </span>
                      </div>
                    )}
                    <div>Geldig tot: {formatDate(subscription.endDate) || ""}</div>
                  </div>
                </div>
              )}
            </div>
            <div className='-mt-8 flex items-center justify-center w-full relative'>
              <div className='md:hidden'>
                <CJP_card 
                  loadingObj={loadingObj} 
                  userProfile={userProfile} 
                  activeSub={activeSub} 
                  subscription={subscription} 
                  togglePopup={togglePopup}
                  showCloseIcon={false}
                />
              </div>
            </div>
          </div>
        )}  
      </div>

      <div className =''>
        {subscription.budgetAvailable > 0 &&
          <div className="w-full overflow-scroll pt-40 md:pt-0">
            <div className="w-full">
              <div className="flex p-4 flex-col items-center ">
                <div className="md:flex md:justify-between items-start w-full">
                  <div className="md:flex md:flex-col">
                    <div className="font-bold font-formula tracking-wide mb-2 text-2xl">Jouw beschikbaar budget</div>           
                    <div>
                      Huidig tegoed: <span className="font-bold text-2xl">€{subscription.budgetAvailable}</span>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center w-full mt-4 mb-4">
                  <div className='w-60'>
                    <SmallButtonYellow
                      text={"Naar het aanbod"}
                      onClick={() => window.location.href = "https://cjp.nl"}
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        } 
      </div> 

      {(isPopupVisible && activeSub) && (
        <div
          className="fixed inset-0 bg-black z-[9999] bg-opacity-50 flex flex-col justify-center items-center transition-all duration-300 ease-in-out"
          onClick={togglePopup}
          style={{
            animation: 'fadeIn 0.3s ease-in-out'
          }}
        >

          {firstTime &&
            <div className='text-white text-2xl font-bold font-formula tracking-[3px]'>
              Jouw CJP is geactiveerd!
            </div>
          }
          <div 
            className="transform transition-all duration-300 ease-in-out"
            style={{
              animation: 'slideUp 0.3s ease-in-out'
            }}
          >
            <CJP_card 
              loadingObj={loadingObj} 
              userProfile={userProfile} 
              activeSub={activeSub} 
              subscription={subscription}
              togglePopup={togglePopup}
            />
          </div>
        </div>
      )}
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          @keyframes slideUp {
            from { 
              opacity: 0;
              transform: translateY(20px);
            }
            to { 
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </PrivateLayout>
  );
};