import { useEffect, useState } from "react"; 

type CodeInputProps = {
  length: number;
  value: string;
  onChange: (value: string) => void;
  darkMode?: boolean;
  error?: boolean;
  errorMsg?: string;
};

export const CodeInput = ({ length, value, onChange, darkMode = false, error = false, errorMsg = '' }: CodeInputProps) => {
  const [inputValues, setInputValues] = useState<string[]>(Array(length).fill(''));
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const placeholder = 'ABC123'; 

  useEffect(() => {
    const valuesArray = value.toUpperCase().split('').slice(0, length); 
    setInputValues((prev) =>
      valuesArray.concat(Array(length - valuesArray.length).fill(''))
    );
  }, [value, length]);

  const handleInputChange = (val: string, index: number) => {
    const upperVal = val.toUpperCase(); 
    if (!/^[a-zA-Z0-9]$/.test(upperVal) && upperVal !== '') return;
    const newValues = [...inputValues];
    newValues[index] = upperVal;
    setInputValues(newValues);
    onChange(newValues.join(''));
    if (upperVal && index < length - 1) {
      document.getElementById(`code-input-${index + 1}`)?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && inputValues[index] === '' && index > 0) {
      document.getElementById(`code-input-${index - 1}`)?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').toUpperCase().slice(0, length);
    if (pasteData.length === length && /^[a-zA-Z0-9]+$/.test(pasteData)) {
      const newValues = pasteData.split('');
      setInputValues(newValues);
      onChange(newValues.join(''));
    }
  };

  const isEmpty = inputValues.every((val) => val === '');

  return (
    <div className="flex flex-col gap-3" onPaste={handlePaste}>
      <div className="flex gap-3 md:gap-4"> 
        {inputValues.map((char, index) => (
          <input
            key={index}
            id={`code-input-${index}`}
            type="text"
            maxLength={1}
            value={char}
            placeholder={isEmpty && !isFocused ? placeholder[index] || '' : ''}
            onChange={(e) => handleInputChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className={`w-10 h-20 rounded-md text-center ${darkMode ? 'bg-gray-500 text-white' : 'bg-white text-black'} ${
              error ? 'border-red-500' : 'border-gray-300'
            } uppercase placeholder-gray-400`} 
            style={{ borderRadius: '0.4rem' }} 
          />
        ))}
      </div>
      {error && <div className="text-red-500 text-sm mt-1">{errorMsg}</div>}
    </div>
  );
};