import { useEffect, useState } from "react";
import { BaseModal } from 'component-library'
import { cancelSubscription, changeEmail, changePassword, deleteProfile, getProfile, getSubscriptions, updateProfile } from "@components/api/API";
import { AdjustmentsVerticalIcon, UserIcon } from "@heroicons/react/24/outline";
// import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { useNotification } from "@context/Notification/NotificationContext";
import { AccountSettings } from "@components/mijn/AccountSettings";
import { AccountOverview } from "@components/mijn/AccountOverview";
import { useUser } from "@context/UserContext";
import { CreditCardIcon } from "@heroicons/react/20/solid";
import { AccountPreferences } from "@components/mijn/AccountPreferences";

type AccountModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const AccountModalConstructor = ({ openModal, toggleModal, darkMode = false }: AccountModalProps) => {
  // const customNavigate = useCustomNavigate(); 
  const { notify } = useNotification();
  const { handleLogout } = useUser()
  const [activeSub, setActiveSub] = useState(false)  
  const [loginDetails, setLoginDetails] = useState({
    password: ""
  } as any)
  const [userProfile, setUserProfile] = useState({} as any)
  const [subscription, setSubscription] = useState({} as any)
  const [subscriptions, setSubscriptions] = useState([] as any)
  const [selectedTab, setSelectedTab]   = useState(0)
  const [resetPass, setResetPass] = useState({password: '', repeatPassword: ''})
  const [optIns, setOptIns] = useState({ emailMarketing: false, pushNotifications: false })
  const [resetEmail, setResetEmail] = useState({email: '', confirmEmail: ''})
  const [restitution, setRestitution] = useState(0)
  const [loadingObj, setLoadingObj] = useState({
    update: false,
    pass: false,
    logout: false,
    optIn: false,
    subscription: true,
    profile: true,
    email: false
  })

  const handleLoadingObj = (key: string, value: boolean) => {
    setLoadingObj(prevState => ({
      ...prevState,
      [key as keyof typeof prevState]: value
    }));
  };

  useEffect(() => {
    getUserProfile()
    getUserSubscription()
  }, [])

  const getUserSubscription = async () => {
    const response = await getSubscriptions()
    if(response.status === 200){
      let data = response.data
      if(data.data?.length > 0){
        // console.log('data', data?.data)
        const activeSubscription = data.data.find((sub: any) => {
          const endDate = new Date(sub.endDate);
          return endDate > new Date();
        });
        
        setSubscriptions([...data.data])
        if (activeSubscription) {
          setActiveSub(true)
          setSubscription(activeSubscription)
        } else {
          setActiveSub(false)
          setSubscription({})
        }
      }
    }
    handleLoadingObj('subscription', false)
  }

  const getUserProfile = async () => {
    const response = await getProfile()
    if(response.status === 200){
      let data = response.data.data
      setLoginDetails({...data})
      setUserProfile({...data})
      if(data.optInPreferences)
      setOptIns({...data.optInPreferences})
    } else { 
      console.log('failed to get profile')
    }
    handleLoadingObj('profile', false)
  }
 
  const handleUpdateAccount = async (data: any) => {
    handleLoadingObj('update', true)
    let body = data
    // body.dateBirth = "2000-01-21"

    const response = await updateProfile(body)
    if(response.status === 200){
      getUserProfile()
      handleLoadingObj('update', false)
      notify("Account gegevens zijn opgeslagen.", "success")
    } 
    else {
      notify("Er is een fout opgetreden.", "error")
    }
  } 

  const handleUpdatePass = async () => {
    handleLoadingObj('pass', true)
    const response = await changePassword(resetPass.password)
    if(response.status === 200){
      handleLoadingObj('pass', false)
      setResetPass({password: '', repeatPassword: ''})
      notify("Wachtwoord aanpassing is opgeslagen.", "success")
    }
    else{
      notify("Er is een fout opgetreden.", "error")
    }
  }

  const handleUpdateEmail = async () => {
    handleLoadingObj('email', true)
    const response = await changeEmail(resetEmail.email)
    if(response.status === 200){
      handleLoadingObj('email', false)
      setResetEmail({email: '', confirmEmail: ''})
      notify("Email reset is aangevraagd, u krijgt een bevestigings link in het nieuwe email adres.", "success")
    }
    else{
      notify("Er is een fout opgetreden.", "error")
    }
  }

  const handleClickedLogOut = async () => {
    if(handleLogout)
      handleLogout()
  }

  const handleUpdateOptIn = async () => {
    handleLoadingObj('optIn', true)
    const data = {optInPreferences: {
       emailMarketing: JSON.stringify(optIns.emailMarketing), 
       pushNotifications: JSON.stringify(optIns.pushNotifications)
      }
    }
    await handleUpdateAccount(data)
    handleLoadingObj('optIn', false)
  } 

  const handleCloseAccount = async () => {
    const response = await deleteProfile()
    if(response.status === 200){
      notify("Account is gesloten.", "success")
      // customNavigate('/inloggen')
      if (handleLogout) handleLogout()
    } else {
      notify("Er is een fout opgetreden.", "error")
    }
  }

  const handleCancelSubscription = async () => {
    const response = await cancelSubscription()
    if(response.status === 200){
      notify("Abonnement is geannuleerd.", "success")
      setRestitution(response.data.data.refund)
      getUserSubscription()
    } else {
      notify("Er is een fout opgetreden.", "error")
    }
  }

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
    > 
      <div className="flex flex-row w-full absolute inset-0 h-fit justify-center bg-white">
        <div className={` ${selectedTab === 0 ? "bg-main-blue text-white" : "hover:bg-slate-100 cursor-pointer text-slate-300" } animate flex-1 py-4`} onClick={()=>setSelectedTab(0)}>
          <CreditCardIcon className="w-8 h-8 w-fit m-auto" />
        </div>
        <div className={` ${selectedTab === 1 ? "bg-main-blue text-white" : "hover:bg-slate-100 cursor-pointer text-slate-300" } animate flex-1 py-4`} onClick={()=>setSelectedTab(1)}>
          <UserIcon className="w-8 h-8 w-fit m-auto" />
        </div>
        <div className={` ${selectedTab === 2 ? "bg-main-blue text-white" : "hover:bg-slate-100 cursor-pointer text-slate-300" } animate flex-1 py-4`} onClick={()=>setSelectedTab(2)}>
          <AdjustmentsVerticalIcon className="w-8 h-8 w-fit m-auto" />
        </div>
      </div>
      {selectedTab === 0 &&
        <AccountOverview 
          darkMode={darkMode}
          loadingObj={loadingObj}
          userProfile={userProfile}
          activeSub={activeSub}
          subscription={subscription}
          />
        }
      {selectedTab === 1 &&
        <AccountSettings
          handleLogout={handleClickedLogOut}
          darkMode={darkMode}
          loginDetails={loginDetails}
          setLoginDetails={setLoginDetails} 
          handleUpdateAccount={handleUpdateAccount}
          handleUpdateOptIn={handleUpdateOptIn}
          getUserProfile={getUserProfile}
          userProfile={userProfile}
          loadingObj={loadingObj}
          optIns={optIns}
          setOptIns={setOptIns}
        />
      }
      {selectedTab === 2 &&
        <AccountPreferences
          handleLogout={handleClickedLogOut}
          subscriptions={subscriptions}
          darkMode={darkMode}
          handleUpdatePass={handleUpdatePass}
          resetPass={resetPass}
          setResetPass={setResetPass}
          handleUpdateEmail={handleUpdateEmail}
          resetEmail={resetEmail}
          setResetEmail={setResetEmail}
          getUserProfile={getUserProfile}
          loadingObj={loadingObj}
          handleCancelSubscription={handleCancelSubscription}
          handleCloseAccount={handleCloseAccount}
          restitution={restitution}
        />
      }
    </BaseModal>
  )
}