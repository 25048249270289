import { InputField, SmallButtonYellow } from "component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cancelSubscription, changeEmail, changePassword, deleteProfile } from "@components/api/API";
import { useUser } from "@context/UserContext";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { useNotification } from "@context/Notification/NotificationContext";
import { checkCompromisedPassword } from "@components/api/API";
import { CheckIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { PrivateLayout } from '@components/layout/PrivateLayout';
import { LoadingSkeleton } from "@components/skeleton/LoadingSkeleton";

const ItemHeaderSidebar = [
  {
    text: "Mijn gegevens",
    link: "/my-profile/my-details"
  },
  {
    text: "Instellingen",
    link: "/my-profile/instellingen"
  }
];

export const MySettings = () => {
  const { t } = useTranslation()
  const { isLoading, isAuth, subscriptions, refreshSubscriptions, handleLogout } = useUser() as any;
  const customNavigate = useCustomNavigate(); 
  const { notify } = useNotification(); 
  const [resetPass, setResetPass] = useState({password: '', repeatPassword: ''})
  const [resetEmail, setResetEmail] = useState({email: '', confirmEmail: ''})
  const [restitution, setRestitution] = useState(0)
  const [areYouSure, setAreYouSure] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [loadingClose] = useState(false);

  const [loadingObj, setLoadingObj] = useState({
    update: false,
    pass: false,
    logout: false,
    optIn: false,
    subscription: true,
    profile: true,
    email: false
  });

  const [validations, setValidations] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    isLongEnough: false,
    isNotComporimised: false,
  });  

  const handleLoadingObj = (key: string, value: boolean) => {
    setLoadingObj(prevState => ({
      ...prevState,
      [key as keyof typeof prevState]: value
    }));
  };

  useEffect(() => {
    if(!isLoading){
      if(isAuth){
        handleLoadingObj('profile', false)
        handleLoadingObj('subscription', false)
      } else {
        customNavigate("/inloggen")
      }
    }
  }, [isLoading, isAuth])

  const handleUpdatePass = async () => {
    if (resetPass.password !== resetPass.repeatPassword) {
      notify("Wachtwoorden komen niet overeen.", "error");
      return;
    }

    if (!validations.isNotComporimised || 
      !validations.hasUpperCase || 
      !validations.hasLowerCase || 
      !validations.hasNumber || 
      !validations.isLongEnough) {
      notify("Het wachtwoord voldoet niet aan de vereisten.", "error");
      return;
    }

    handleLoadingObj('pass', true);

    try {
      const response = await changePassword(resetPass.password);
      if (response.status === 200) {
        setResetPass({ password: '', repeatPassword: '' });
        notify("Wachtwoord succesvol gewijzigd.", "success");
      } else {
        notify("Er is een fout opgetreden.", "error");
      }
    } catch (error) {
      notify("Er is een fout opgetreden.", "error");
    } finally {
      handleLoadingObj('pass', false);
    }
  };

  const handleUpdateEmail = async () => {
    handleLoadingObj('email', true)
    const response = await changeEmail(resetEmail.email)
    if(response.status === 200){
      handleLoadingObj('email', false)
      setResetEmail({email: '', confirmEmail: ''})
      notify("Email reset is aangevraagd, u krijgt een bevestigings link in het nieuwe email adres.", "success")
    }
    else{
      notify("Er is een fout opgetreden.", "error")
    }
  }

  const validatePassword = async (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isLongEnough = password.length >= 8;
    const isNotComporimised = await checkPassword();

    setValidations({
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      isLongEnough,
      isNotComporimised,
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      validatePassword(resetPass.password);
    }, 250);
  
    return () => clearTimeout(timeoutId);
  }, [resetPass.password]);

  const checkPassword = async () => {
    if (resetPass.password?.length < 7) return false;
    const res = await checkCompromisedPassword(resetPass.password);
    return res.status === 200;
  };

  const handleCloseAccount = async () => {
    const response = await deleteProfile()
    if(response.status === 200){
      notify("Account is gesloten.", "success")
      if (handleLogout) handleLogout()
    } else {
      notify("Er is een fout opgetreden.", "error")
    }
  }

  const handleCancelSubscription = async () => {
    const response = await cancelSubscription()
    if(response.status === 200){
      notify("Abonnement is geannuleerd.", "success")
      setRestitution(response.data.data.refund)
      refreshSubscriptions()
    } else {
      notify("Er is een fout opgetreden.", "error")
    }
  }

  const isFutureDate = (isoDateString: string): boolean => { 
    const suppliedDate = new Date(isoDateString); 
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); 
    if (isNaN(suppliedDate.getTime())) {
      throw new Error('Invalid date format. Please use a valid ISO 8601 date string.');
    } 
    const suppliedDateOnly = new Date(suppliedDate);
    suppliedDateOnly.setHours(0, 0, 0, 0); 
    return suppliedDateOnly.getTime() > currentDate.getTime();
  };

  const findIndividualSubscription = () => { 
    let found = false
    subscriptions.forEach((sub: any) => { 
      if(sub.name.includes('Jongerenpas') || sub.name.includes('Individual')){
        if(isFutureDate(sub.endDate) && sub.autoRenew) found = true 
      }
    })
    if(restitution > 0) return false 
    return found
  }

  useEffect(() => {
    document.title = "CJP | Instellingen";
    return () => {
      document.title = "CJP";
    };
  }, []);

  return (
    <PrivateLayout headerItems={ItemHeaderSidebar}>
      <div className="font-bold font-formula tracking-wide pt-2 text-2xl">Instellingen</div>
      <div className="font-bold text-main-dark pt-2 text-lg">{"Wachtwoord aanpassen"}</div>
      <div className="font-bold md:flex lg:flex gap-6">
        <div className="w-80">
          <LoadingSkeleton isLoading={loadingObj.profile}>
            <InputField
              placeholder={"Nieuw wachtwoord"}
              value={resetPass.password || ""}
              setState={() => {}}
              type="password"
              error={resetPass.password.length > 0 && !validations.isNotComporimised}
              handleUpdate={(value: any) => setResetPass({ ...resetPass, password: value })}
              itemKey={"password"}
              darkMode={false}
            />
          </LoadingSkeleton>
        </div>
        <div className="w-80">
          <LoadingSkeleton isLoading={loadingObj.profile}>
            <InputField
              placeholder={"Herhaal wachtwoord"}
              value={resetPass.repeatPassword || ""}
              setState={() => {}}
              type="password"
              error={resetPass.repeatPassword.length > 0 && resetPass.repeatPassword !== resetPass.password}
              handleUpdate={(value: any) => setResetPass({ ...resetPass, repeatPassword: value })}
              itemKey={"repeatPassword"}
              darkMode={false}
            />
          </LoadingSkeleton>
        </div>
      </div>

      {(resetPass.password.length > 0 && (!validations.isNotComporimised || !validations.isLongEnough)) &&
        <div className="text-sm">
          <div className="flex flex-row gap-x-2">
            <div>
              {validations.hasUpperCase ? 
                <CheckIcon className="w-5 h-5 text-green-500" /> : 
                <XMarkIcon className="w-5 h-5 text-red-500" />}
            </div>
            <div>{t("passwordUpperCase")}</div>
          </div>
          <div className="flex flex-row gap-x-2">
            <div>
              {validations.hasLowerCase ? 
                <CheckIcon className="w-5 h-5 text-green-500" /> : 
                <XMarkIcon className="w-5 h-5 text-red-500" />}
            </div>
            <div>{t("passwordLowerCase")}</div>
          </div>
          <div className="flex flex-row gap-x-2">
            <div>
              {validations.hasNumber ? 
                <CheckIcon className="w-5 h-5 text-green-500" /> : 
                <XMarkIcon className="w-5 h-5 text-red-500" />}
            </div>
            <div>{t("passwordNumber")}</div>
          </div>
          <div className="flex flex-row gap-x-2">
            <div>
              {validations.isLongEnough ? 
                <CheckIcon className="w-5 h-5 text-green-500" /> : 
                <XMarkIcon className="w-5 h-5 text-red-500" />}
            </div>
            <div>{t("passwordIsLongEnough")}</div>
          </div>
          {resetPass.password.length > 7 &&
            <div className="flex flex-row gap-x-2">
              <div>
                {validations.isNotComporimised ? 
                  <CheckIcon className="w-5 h-5 text-green-500" /> : 
                  <XMarkIcon className="w-5 h-5 text-red-500" />}
              </div>
              <div>{"Wachtwoord is gevonden in database met gelekte wachtwoorden."}</div>
            </div>
          }
        </div>
      }

      <div className="flex justify-center items-center mt-12 mb-1">
        <div className="w-56">
          <LoadingSkeleton isLoading={loadingObj.profile} width="w-56" height="h-10" containerHeight="h-10" topPadding="mt-0">
            <SmallButtonYellow
              text={"Aanpassen"}
              onClick={() => handleUpdatePass()}
              disabled={
                resetPass.password.length < 1 ||
                resetPass.password !== resetPass.repeatPassword ||
                !validations.isNotComporimised
              }
              darkMode={false}
              loading={loadingObj.pass}
            />
          </LoadingSkeleton>
        </div>
      </div>

      <hr className="my-12" />

      <div className="font-bold text-main-dark pt-2 text-lg">2FA Instellen</div>
      <div className="flex justify-center items-center mt-6 mb-1">
        <div className="w-56">
          <LoadingSkeleton isLoading={loadingObj.profile} width="w-56" height="h-10" containerHeight="h-10" topPadding="mt-0">
            <SmallButtonYellow text={"Instellen"} onClick={() => customNavigate("/setup-2fa")} darkMode={false} />
          </LoadingSkeleton>
        </div>
      </div>

      <hr className="my-12" />

      <div className="font-bold text-main-dark pt-2 text-lg">E-mailadres aanpassen</div>
      <div className="font-bold md:flex lg:flex gap-6">
        <div className="w-80">
          <LoadingSkeleton isLoading={loadingObj.profile}>
            <InputField
              placeholder={"Nieuw e-mailadres"}
              value={resetEmail.email || ""}
              setState={() => {}}
              type="text"
              error={resetEmail.email.length > 0 && resetEmail.email !== resetEmail.confirmEmail}
              handleUpdate={(value: any) => setResetEmail({ ...resetEmail, email: value })}
              itemKey={"email"}
              darkMode={false}
            />
          </LoadingSkeleton>
        </div>
        <div className="w-80">
          <LoadingSkeleton isLoading={loadingObj.profile}>
            <InputField
              placeholder={"Bevestig e-mailadres"}
              value={resetEmail.confirmEmail || ""}
              setState={() => {}}
              type="text"
              error={resetEmail.confirmEmail.length > 0 && resetEmail.email !== resetEmail.confirmEmail}
              handleUpdate={(value: any) => setResetEmail({ ...resetEmail, confirmEmail: value })}
              itemKey={"confirmEmail"}
              darkMode={false}
            />
          </LoadingSkeleton>
        </div>
      </div>
      <div className="flex justify-center items-center mt-12 mb-1">
        <div className="w-56">
          <LoadingSkeleton isLoading={loadingObj.profile} width="w-56" height="h-10" containerHeight="h-10" topPadding="mt-0">
            <SmallButtonYellow
              text={"Aanpassen"}
              onClick={() => handleUpdateEmail()}
              disabled={
                resetEmail.email.length < 1 || resetEmail.email !== resetEmail.confirmEmail
              }
              darkMode={false}
              loading={loadingObj.email}
            />
          </LoadingSkeleton>
        </div>
      </div>

      <hr className="my-12" />

      <div className="font-bold text-main-dark pt-2 text-lg">Abonnement opzeggen</div>
      <div className="flex mt-4 flex-col">
        <p>Let op: bij het stoppen van je lidmaatschap heb je geen toegang meer tot de kortingen en deals van CJP!</p>
        <div className="flex justify-center items-center mt-12 mb-1">
          <div className="w-56">
            <LoadingSkeleton isLoading={loadingObj.subscription} width="w-56" height="h-10" containerHeight="h-10" topPadding="mt-0">
              <SmallButtonYellow
                text={"Opzeggen"}
                onClick={() => handleCancelSubscription()}
                darkMode={false}
                loading={loadingObj.subscription}
                disabled={!findIndividualSubscription()}
              />
            </LoadingSkeleton>
          </div>
        </div>
      </div>

      <hr className="my-12" />

      <div className="font-bold text-main-dark pt-2 text-lg">Account sluiten</div>
      <div className="flex mt-4 flex-col">
        <p>Let op: na het sluiten van je account wordt je lidmaatschap direct beëindigd.</p>
        <div className="mt-12 mb-1">
          {areYouSure ? (
            <div>
              <div>
                <div className="font-bold text-main-color text-lg">
                  Weet je zeker dat je je account wil sluiten? 
                </div>
                <div>
                  Het verwijderen van je account betekent dat je niet meer kunt inloggen en je lidmaatschap per direct wordt stopgezet. Je hebt dus geen recht meer op de deals van CJP. Ook je persoonsgegevens worden verwijderd.
                  <br/><br/>
                  Typ CJPWEGERMEE in het onderstaande veld om je account definitief te verwijderen.
                  <br/><br/>
                  Nadat je op verwijderen klikt word je automatisch uitgelogd.
                  <br/><br/>
                </div>
                <div className="w-56">
                  <LoadingSkeleton isLoading={loadingObj.profile}>
                    <InputField
                      placeholder={"CJPWEGERMEE"}
                      value={confirmText}
                      handleUpdate={(value) => setConfirmText(value)}
                      darkMode={false}
                    />
                  </LoadingSkeleton>
                </div>
              </div>
              <div className="flex justify-center items-center mt-12 mb-1">
                <div className="w-56">
                  <LoadingSkeleton isLoading={loadingObj.profile} width="w-56" height="h-10" containerHeight="h-10" topPadding="mt-0">
                    <SmallButtonYellow
                      text={"Verwijderen"}
                      onClick={handleCloseAccount}
                      disabled={confirmText !== "CJPWEGERMEE" || loadingClose}
                      darkMode={false}
                      loading={loadingClose}
                    />
                  </LoadingSkeleton>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center mt-12 mb-1">
              <div className="w-56">
                <LoadingSkeleton isLoading={loadingObj.profile} width="w-56" height="h-10" containerHeight="h-10" topPadding="mt-0">
                  <SmallButtonYellow
                    text={"Sluiten"}
                    onClick={() => setAreYouSure(true)} 
                    darkMode={false}
                    loading={false}
                  />
                </LoadingSkeleton>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <div className="flex justify-center items-center mt-6 mb-1">
        <div className="w-56">
          <SmallButtonYellow text={"Uitloggen"} onClick={handleClickedLogOut} darkMode={false} />
        </div>
      </div> */}
    </PrivateLayout>
  );  
}