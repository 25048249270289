import { useState } from "react";
import PointerImg from '@assets/PointerImg.png'
import CardImage from '@assets/CardImage.png'
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";

type ChooseCJPModalProps = {
  darkMode?: boolean;
}

export const ChooseCJPSelector = ({ darkMode }: ChooseCJPModalProps) => {
  const { t } = useTranslation();
  const customNavigate = useCustomNavigate();
  const [showContent, setShowContent] = useState(true);

  return (
    <div className="gap-y-5 flex flex-col flex-1">
      <div className="flex relative h-52">
        <div
          className={`w-full cursor-pointer  ${darkMode ? "bg-white hover:bg-white/90" : "bg-slate-100 hover:bg-slate-200"} rounded-lg p-4 flex absolute animate ${showContent ? "translate-x-0" : "-translate-x-[120%]"}`}
          onClick={() => setShowContent(!showContent)}
        >
          <div className="uppercase bg-main-yellow aspect-square absolute -top-4 left-2 p-2 text-black/80 font-bold font-formula tracking-wider flex items-center text-xl">
            {t('free')}
          </div>
          <div>
            <img src={CardImage} alt="gratisImg" className="min-w-24 aspect-[3/5]" />
          </div>
          <div className="w-[calc(100%-9rem)] mt-6 pl-12 text-black/80">
            {/* <div className="uppercase font-bold font-formula tracking-wide text-4xl">
              {t('free')}
            </div> */}
            <div className="uppercase font-bold font-formula text-2xl tracking-wide">
              {t('freeSubtitle')}
            </div>
            <div className="flex gap-x-2">
              <img 
                src={PointerImg}
                className="w-4 h-4 mt-1"
              />
              {t('freePerks1')}
            </div>
            <div className="flex gap-x-2">
              <img 
                src={PointerImg}
                className="w-4 h-4 "
              />
              {t('freePerks2')}
            </div>
          </div>
        </div>
        <div className={`w-full flex gap-x-4 absolute animate ${!showContent ? "translate-x-0" : "translate-x-[120%]"}`}>
          <div 
            className={`cursor-pointer ${darkMode ? "bg-white hover:bg-white/90" : "bg-slate-100 hover:bg-slate-200"} active:bg-white/80 rounded-lg w-1/2 relative aspect-square flex items-center justify-center`} 
            onClick={() => customNavigate('/lidmaatschap/vo')}
          >
            {/* <div className="uppercase bg-main-yellow aspect-square absolute -top-4 left-2 p-1 text-black/80 font-bold font-formula tracking-wider flex items-center text-xl">
              {t('free')}
            </div> */}
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-black/80 text-center">
                <div className="uppercase font-bold font-formula tracking-wide text-2xl mb-4">
                  {/* {t('areYou')} */}
                </div>
                <div className="uppercase font-bold font-formula text-4xl tracking-wide">
                  {t('voStudentSubtitle')}
                </div>
              </div>
            </div>
          </div>
          <div 
            className={`cursor-pointer ${darkMode ? "bg-white hover:bg-white/90" : "bg-slate-100 hover:bg-slate-200"} active:bg-white/80 rounded-lg w-1/2 relative flex items-center justify-center aspect-square`} 
            onClick={() => customNavigate('/lidmaatschap/mbo')}
          >
            {/* <div className="uppercase bg-main-yellow aspect-square absolute -top-4 left-2 p-1 text-black/80 font-bold font-formula tracking-wider flex items-center text-xl">
              {t('free')}
            </div> */}
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-black/80 text-center">
                <div className="uppercase font-bold font-formula tracking-wide text-2xl mb-4">
                  {/* {t('areYou')} */}
                </div>
                <div className="uppercase font-bold font-formula text-4xl tracking-wide">
                  {t('mboStudentSubtitle')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`w-full animate ${darkMode ? "bg-white hover:bg-white/90" : "bg-slate-100 hover:bg-slate-200"} rounded-lg p-4 flex relative cursor-pointer mt-12`} 
        onClick={() => customNavigate('/lidmaatschap/kopen')}
      >
        <div className="bg-main-yellow aspect-square absolute -top-4 left-2 p-3 text-black/80 font-bold font-formula tracking-wider flex items-center text-xl">
          €1O.-
        </div>
        <div>
          <img src={CardImage} alt="gratisImg" className="min-w-24 aspect-[3/5]" />
        </div>
        <div className="w-[calc(100%-9rem)] pl-10 text-black/80">
          <div className="uppercase font-bold font-formula tracking-wide text-4xl">
            {/* {t('becomeMember')} */}
          </div>
          <div className="uppercase font-bold font-formula text-2xl tracking-wide">
            {t('becomeMemberSubtitle')}
          </div>
          <div className="flex gap-x-2">
            <img 
              src={PointerImg}
              className="w-4 h-4 mt-1"
            />
            {t('becomeMemberPerk1')}
          </div> 
          <div className="flex gap-x-2">
            <img 
              src={PointerImg}
              className="w-4 h-4 mt-1"
            />
            {t('becomeMemberPerk2')}
          </div> 
        </div>
      </div>
    </div>
  );
};
