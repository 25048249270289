import { useEffect, useState, useRef } from "react"; 
import { useSearchParams } from 'react-router-dom'; 
import { BaseModal, InputField, SmallButtonYellow, InvertedSmallButton } from 'component-library' 
import { activateSubscription, login, verifyCode } from "@api/API";
import { useTranslation } from "react-i18next";
import { useUser } from "@context/UserContext";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate"; 
import { useNotification } from "@context/Notification/NotificationContext";
import { EmailCheck } from "@regex/EmailCheck";

type ActivateAccountModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

type CodeInputProps = {
  length: number;
  value: string;
  onChange: (value: string) => void;
  darkMode?: boolean;
  error?: boolean;
  errorMsg?: string;
};

const CodeInput = ({ length, value, onChange, darkMode = false, error = false, errorMsg = '' }: CodeInputProps) => {
  const [inputValues, setInputValues] = useState<string[]>(Array(length).fill(''));

  useEffect(() => {
    const valuesArray = value.toUpperCase().split('').slice(0, length); 
    setInputValues((prev) =>
      valuesArray.concat(Array(length - valuesArray.length).fill(''))
    );
  }, [value, length]);

  const handleInputChange = (val: string, index: number) => {
    const upperVal = val.toUpperCase(); 
    if (!/^[a-zA-Z0-9]$/.test(upperVal) && upperVal !== '') return;

    const newValues = [...inputValues];
    newValues[index] = upperVal;
    setInputValues(newValues);
    onChange(newValues.join(''));

    if (upperVal && index < length - 1) {
      document.getElementById(`code-input-${index + 1}`)?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && inputValues[index] === '' && index > 0) {
      document.getElementById(`code-input-${index - 1}`)?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').toUpperCase().slice(0, length);
    if (pasteData.length === length && /^[a-zA-Z0-9]+$/.test(pasteData)) {
      const newValues = pasteData.split('');
      setInputValues(newValues);
      onChange(newValues.join(''));
    }
  };

  return (
    <div className="flex flex-col gap-3" onPaste={handlePaste}>
      <div className="flex gap-4 justify-center"> 
        {inputValues.map((char, index) => (
          <input
            key={index}
            id={`code-input-${index}`}
            type="text"
            maxLength={1}
            value={char}
            onChange={(e) => handleInputChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            className={`w-12 h-20 text-center ${darkMode ? 'bg-gray-500 text-white' : 'bg-white text-black'} ${
              error ? 'border-red-500' : 'border-gray-300'
            } uppercase `} 
            style={{ borderRadius: '0.4rem' }} 
          />
        ))}
      </div>
      {error && <div className="text-red-500 text-center itams-center text-sm mt-1">{errorMsg}</div>}
    </div>
  );
};

export const ActivateAccountModalConstructor = ({ openModal, toggleModal, darkMode = false }: ActivateAccountModalProps) => {
  const { isAuth, isLoading, user, fetchUserDetails } = useUser() as any;
  const { t } = useTranslation() 
  const { notify } = useNotification();
  const customNavigate = useCustomNavigate();
  const [isError, setIsError] = useState(true)
  const [loading, setLoading] = useState(false)
  const [tempCode, setTempCode] = useState('')
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
    confirmEmail: '',
    dob: '',
    subscribe: false,
    terms: false,
    code: '',
    firstName: '',
    lastName: '',
    postal: '',
    parentEmail: ''
  })
  const [searchParams] = useSearchParams();
  const paramCode = searchParams.get('code') || searchParams.get('activation_code');
  const [parentRequired, setParentRequired] = useState(false)
  const subCode = localStorage.getItem('subCode') || ''

  useEffect(() => {
    if(subCode?.length > 0){
      updateCode(subCode)
    }
  }, [subCode])

  useEffect(() => {
    if(paramCode){
      let tempcode = paramCode
      setTempCode(tempcode)
      //remove all special characters from string
      tempcode = tempcode.replace(/[^a-zA-Z0-9]/g, '')
      //only use first 6 characters
      tempcode.slice(0, 6)
      setLoginDetails({
        ...loginDetails,
        code: tempcode
      })
    }
  }, [paramCode]) 

  useEffect(() =>{
    if(!isLoading){
      if(!isAuth){
        customNavigate('/inloggen')
        notify(t('loginRequired'), "error")
      }
    }
  }, [isAuth, isLoading])

  useEffect(() => {
    checkDate()
  }, [user, loginDetails])

  // const selectedIsToday = () => {
  //   const [day, month, year] = user.dateBirth.split('-').map(Number);
  //   // const birthDate = new Date(year, month - 1, day);
  //   // const today = new Date();
  //   // return selectedDate.toDateString() === today.toDateString();
  // }

  const checkDate = () => {
    //check if user is under 16
    if (!loginDetails.dob) return true;
    const [day, month, year] = loginDetails.dob?.split('-').map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    age < 16 ? setParentRequired(true) : setParentRequired(false)
  }

  useEffect(() =>{
    if(!isLoading){
      if(isAuth){
        updateUserDetails()
      }
    }
  }, [user, isLoading, isAuth])

  useEffect(() => {
    if(!checkDisabled() && !parentRequired && subCode?.length > 0){
      // console.log('ready to activate', loginDetails)
      // checkRegistrationCode()
      handleActivateAccount()
    }
  }, [loginDetails, isError])

  const updateUserDetails = () => { 
    if(loginDetails.firstName.length > 0) {
      // console.log('user details already set')
      return;
    }
    setLoginDetails({
      ...loginDetails, email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      // postal: user.profile.postal,
      dob: user.dateBirth,
      subscribe: user.optInPreferences?.emailMarketing
    })
  }

  const updateCode = (value: string) => {
    let tempcode = value
    setTempCode(tempcode)
    //remove all special characters from string, also spaces and dashes
    tempcode = tempcode.replace(/[^a-zA-Z0-9]/g, '')
    //only use first 6 characters
    tempcode = tempcode.slice(0, 6)

    setLoginDetails({
      ...loginDetails,
      code: tempcode 
    })
  }

  const updateLoginDetails = (value: any, state: string) => {
    setLoginDetails({
      ...loginDetails,
      [state]: value
    })
  } 

  useEffect(() => {
    if(loginDetails?.code?.length > 4 || tempCode?.length > 4){
      checkRegistrationCode()
    }
  }, [loginDetails.code, tempCode])

  const checkRegistrationCode = async () => {
    //only take first 6 characters of subCode
    const subCode6Chars = subCode?.slice(0, 6)
    const response = await verifyCode(loginDetails.code || subCode6Chars)
    if(response?.status === 200){
      setIsError(false)
      setLoginDetails({
        ...loginDetails,
        code: loginDetails.code || subCode,
        firstName: response.data?.data?.schoolMembersObject?.firstName || "",
        lastName: response.data?.data?.schoolMembersObject?.lastName || "",
      })
      // updateLoginDetails(response.data?.data?.schoolMembersObject?.firstName, 'firstName')
      // updateLoginDetails(response.data?.data?.schoolMembersObject?.lastName, 'lastName')
    } else {
      setIsError(true)
      console.log('error verifying code.')
      localStorage.setItem('subCode', "")
    }
  } 

  const checkDisabled = () => {
    if (
      (loginDetails.code.length > 0 || subCode?.length > 0) && 
      isError === false &&
      loginDetails.firstName.length > 0 && 
      loginDetails.lastName.length > 0 && 
      (parentRequired && loginDetails.parentEmail.length > 0 && EmailCheck(loginDetails.parentEmail) || !parentRequired)
      // loginDetails.terms === true 
    ) {
      return false
    } else {
      return true
    }
  }

  const handleActivateAccount = async () => {
    setLoading(true)
    const data = {
      dateOfBirth: loginDetails.dob,
      email: loginDetails.email,
      isSubscriber: String(loginDetails.subscribe),
      postalCode: loginDetails.postal,
      phone: '',
      firstName: loginDetails.firstName,
      lastName: loginDetails.lastName
    } as any
    if(parentRequired){
      data['parentEmail'] = loginDetails.parentEmail
    }
    const response = await activateSubscription(loginDetails.code, data)
    if(response?.status === 200){
      localStorage.setItem('subCode', "")
      customNavigate('/lidmaatschap/success')
    } else {
      console.log('error activating account')
      localStorage.setItem('subCode', "")
      customNavigate("/inloggen")
    }
  }

  // const handleLogin = async () => {   
  //   const response = await login(loginDetails.email, loginDetails.password, "")
  //   if(response.status === 200){
  //     setUserDetails() 
  //   } else if (response.status === 202){ //2fa required
  //     customNavigate('/inloggen')
  //   } else { 
  //     console.log('error', response)
  //   } 
  // }

  const setUserDetails = async () => {
    try {
      
      if(fetchUserDetails){
        fetchUserDetails()
      }   

      //wait 100ms for DB to be finished
      setTimeout(() => {
        customNavigate('/lidmaatschap/activeren') 
      }, 100)
      
    } catch (error) {
      console.log('Failed to fetch user details'); 
    }
  };

  return(
    <div className="flex flex-col overflow-auto h-screen">
      <div className="flex-grow flex items-center overflow-auto">
        <BaseModal
          open={openModal}
          toggleOpen={toggleModal}
          modalTitle={t('activateAccount')}
          // modalSubtitle={t('activateAccountSubtitle')}
          darkMode={darkMode}
        >
          <div className="bg-[#FAFCC6] mx-2 rounded-lg shadow-md p-4 text-center">
            {t('activateAccountSubtitle')}
          </div>  
          <div className='flex flex-col mt-4 mb-8 flex-1'>
            <CodeInput
              length={6} 
              value={tempCode}
              onChange={updateCode}
              darkMode={darkMode}
              error={(loginDetails?.code?.length > 0 || subCode?.length > 0) && isError}
              errorMsg={t('activationCodeError')}
            />
             {/* <InputField
          placeholder={t('activationCode')}
          value={tempCode}
          setState={() => {}}
          type="text"
          error={loginDetails?.code?.length > 0 && isError}
          handleUpdate={updateCode}
          itemKey={'code'}
          darkMode={darkMode}
          errorMsg={t('activationCodeError')}
        /> */}
            {/* {isError &&
              <a className="text-left cursor-pointer underline pt-2" target='_blank' rel='noreferrer' href='https://support.cjp.nl/portal/nl/kb/articles/activeren-digitale-pas-en-cjp-kortingen' >
                {t("needSupport")}
              </a>
            } */}
            {!isError &&
              <div className="mt-4"> 
                <InputField
                  placeholder={t('firstName')}
                  value={loginDetails.firstName}
                  setState={() => {}}
                  type="text"
                  error={loginDetails?.firstName?.length > 0 && loginDetails?.firstName?.length < 2}
                  handleUpdate={updateLoginDetails}
                  itemKey={'firstName'}
                  darkMode={darkMode}
                />
                <InputField
                  placeholder={t('lastName')}
                  value={loginDetails.lastName}
                  setState={() => {}}
                  type="text"
                  error={false}
                  handleUpdate={updateLoginDetails}
                  itemKey={'lastName'}
                  darkMode={darkMode}
                />
                {parentRequired &&
                  <div className="">
                    <InputField
                      placeholder={t("parentEmail")}
                      value={loginDetails.parentEmail}
                      setState={() => {}}
                      type="text"
                      error={loginDetails.parentEmail.length > 0 && !EmailCheck(loginDetails.parentEmail)}
                      handleUpdate={updateLoginDetails}
                      itemKey={'parentEmail'}
                      darkMode={darkMode}
                      errorMsg={t("emailError")}
                    />
                    <div className="text-sm text-gray-500 pt-3">
                      {t('parentEmailExplanation')}
                    </div>
                  </div> 
                }
              </div>
            }
          </div> 
          <div className='w-full flex flex-col px-8 mt-8 gap-y-4'>
            <SmallButtonYellow
              text={t('activateButton')}
              onClick={() => handleActivateAccount()}
              disabled={checkDisabled()}
              darkMode={darkMode}
              loading={loading}
            />
              <SmallButtonYellow
                text={t('noCode')}
                onClick={() => customNavigate('/lidmaatschap')}
                darkMode={darkMode}
                inverted
              />
            {/* <InvertedSmallButton
              text={t('noCode')}
              onClick={() => customNavigate('/lidmaatschap')}
              darkMode={darkMode}
              // disabled={loginDetails.email.length == 0 || EmailCheck(loginDetails.email)}
            />  */}
          </div> 
        </BaseModal>
      </div>
    </div>
  )
}