import { CustomDatePicker } from "@components/CustomDatePicker";
import { InputField, Slider, SmallButtonYellow } from "component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateProfile } from "@components/api/API";
import { useNotification } from "@context/Notification/NotificationContext";
import { LoadingSkeleton } from "@components/skeleton/LoadingSkeleton";
import { PrivateLayout } from '@components/layout/PrivateLayout';
import { useUser } from "@context/UserContext";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";

const ItemHeaderSidebar = [
  {
    text: "Mijn gegevens",
    link: "/my-profile/my-details"
  },
  {
    text: "Instellingen",
    link: "/my-profile/instellingen"
  }
];

export const MyProfile = ( ) => {
  const { t } = useTranslation()
  const { user, isLoading, isAuth, fetchUserDetails } = useUser() as any;
  const customNavigate = useCustomNavigate();
  const [errorObj, setErrorObj] = useState({date: false}) 
  const { notify } = useNotification();
  const [loginDetails, setLoginDetails] = useState({password: ""} as any)
  const [loadingUpdate, setLoadingUpdate] = useState(false); 
  const [loadingOptIn, setLoadingOptIn] = useState(false); 
  const [optIns, setOptIns] = useState({ emailMarketing: false, pushNotifications: false })
  const darkMode = false;
  const [loadingObj, setLoadingObj] = useState({
    update: false,
    pass: false,
    logout: false,
    optIn: false,
    subscription: false, 
    profile: true,
    email: false
  });

  useEffect(() => {
    if(!isLoading){
      if(isAuth){
        setLoginDetails({...user})
        setOptIns({...user?.optInPreferences})
        handleLoadingObj('profile', false)
      } else {
        customNavigate('/inloggen')
      }
    }
  }, [user, isLoading, isAuth])

  const checkDate = () => {
    const dateBirth = loginDetails.dateBirth || ""; 
    const [day, month, year] = dateBirth.split('-').map(Number); 
  
    if (!day || !month || !year) {
      setErrorObj({
        ...errorObj,
        date: true
      });
      return;
    }
  
    const selectedDate = new Date(year, month - 1, day);
    if (
      selectedDate > new Date() || 
      selectedDate.getFullYear() < 1925 || 
      selectedDate.getFullYear() > new Date().getFullYear() - 12
    ) {
      setErrorObj({
        ...errorObj,
        date: true
      });
    } else {
      setErrorObj({
        ...errorObj,
        date: false
      });
    }
  };

  const updateLoginDetails = (value: any, state: string) => {
    if (state === 'phone') {
      value = formatPhoneNumber(value);
    }

    setLoginDetails({
      ...loginDetails,
      [state]: value
    })
  } 

  const updateDate = (date: string) => { 
    setLoginDetails({
      ...loginDetails,
      dateBirth: date
    })
  } 

  const updateOptIn = (value: any, state: string) => {
    setOptIns({
      ...optIns,
      [state]: value
    })
  }

  const updateAddress = (value: any, state: string) => {
    if (state === 'zip') {
      value = formatPostalCode(value);
    }
  
    setLoginDetails({
      ...loginDetails,
      address: {
        ...loginDetails.address,
        [state]: value
      }
    });
  };

  const formatPhoneNumber = (value: string) => {
    return value.replace(/\D/g, '');
  };

  const checkDetailDifference = () => {
    const profile = JSON.stringify(user)
    const details = JSON.stringify(loginDetails)
    if(errorObj.date){
      return true
    }
    return profile === details
  }

  const formatPostalCode = (value: string) => {
    value = value.replace(/\s+/g, '').toUpperCase();
  
    const digits = value.slice(0, 4).replace(/\D/g, '');
    const letters = value.slice(4, 6).replace(/[^A-Z]/g, '');
  
    return `${digits}${letters}`;
  };

  const compareOptIn = () => {
    const obj1 = JSON.stringify(user?.optInPreferences)
    const obj2 = JSON.stringify(optIns)
    return obj1 === obj2 
  } 

  const handleUpdate = async () => {
    setLoadingUpdate(true);  
    let body = loginDetails;
    await handleUpdateAccount(body);
    setLoadingUpdate(false);  
  };

  const handleLoadingObj = (key: string, value: boolean) => {
    setLoadingObj(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleUpdateAccount = async (data: any) => {
    handleLoadingObj('update', true)
    let body = data
    console.log(body)
    const response = await updateProfile(body)
    if(response.status === 200){
      fetchUserDetails();
      handleLoadingObj('update', false)
      notify("Account gegevens zijn opgeslagen.", "success")
    } 
    else {
      notify("Er is een fout opgetreden.", "error")
    }
  }

  const handleUpdateOptIn = async () => {
    setLoadingOptIn(true); 
    const data = {
      optInPreferences: {
        emailMarketing: JSON.stringify(optIns.emailMarketing),
        pushNotifications: JSON.stringify(optIns.pushNotifications)
      }
    };
    await handleUpdateAccount(data);
    setLoadingOptIn(false); 
  };  

  useEffect(() => {
    checkDate()
  }, [loginDetails.dateBirth])

  useEffect(() => {
    document.title = "CJP | Mijn gegevens";
    return () => {
      document.title = "CJP"; // Reset on unmount if needed
    };
  }, []);

  return (
    <PrivateLayout headerItems={ItemHeaderSidebar}>
      <div className="flex-1">
        <div className="font-bold font-formula tracking-wide pt-2 text-2xl">Mijn gegevens</div>
        <div className="flex mt-4 flex-col">
          <div className="font-bold md:flex lg:flex gap-6">
            <div className="w-80">
              <LoadingSkeleton isLoading={loadingObj.profile}>
                <InputField
                  placeholder={"E-mailadres"}
                  value={loginDetails.email || ""}
                  setState={() => {}}
                  type="text"
                  itemKey="email"
                  error={false}
                  darkMode={darkMode}
                  disabled={true}
                  className="border-gray-400 focus:border-gray-500"
                  color="gray-800"
                />
              </LoadingSkeleton>
            </div>
            <div className="w-80">
              <LoadingSkeleton isLoading={loadingObj.profile}>
                <InputField
                  placeholder={"Telefoonnummer"}
                  value={loginDetails.phone || ""}
                  setState={() => {}}
                  type="text"
                  error={false}
                  handleUpdate={updateLoginDetails}
                  itemKey={'phone'}
                  darkMode={darkMode}
                  disabled={false}
                />
              </LoadingSkeleton>
            </div>
          </div>
          <div className="flex flex-col md:block lg:block sm:gap-x-6">
            <div className="font-bold md:flex lg:flex gap-6">
              <div className="w-80">
                <LoadingSkeleton isLoading={loadingObj.profile}>
                  <InputField
                    placeholder={"Voornaam"}
                    value={loginDetails.firstName || ""}
                    setState={() => {}}
                    type="text"
                    itemKey="firstName"
                    error={false}
                    handleUpdate={updateLoginDetails}
                    darkMode={darkMode}
                    disabled={false}
                  />
                </LoadingSkeleton>
              </div>
              <div className="w-80">
                <LoadingSkeleton isLoading={loadingObj.profile}>
                  <InputField
                    placeholder={"Achternaam"}
                    value={loginDetails.lastName || ""}
                    setState={() => {}}
                    type="text"
                    itemKey="lastName"
                    error={false}
                    handleUpdate={updateLoginDetails}
                    darkMode={darkMode}
                    disabled={false}
                  />
                </LoadingSkeleton>
              </div>
            </div>
            <div className="flex flex-col md:block lg:block sm:gap-x-6">
              <div className="font-bold md:flex lg:flex gap-6">
                <div className="w-80">
                  <LoadingSkeleton isLoading={loadingObj.profile}>
                    <CustomDatePicker
                      date={loginDetails.dateBirth || ""}
                      updateDate={updateDate}
                      errorObj={errorObj}
                      fullWidth={false}
                    />
                  </LoadingSkeleton>
                </div>
                <div className="w-80">
                  <LoadingSkeleton isLoading={loadingObj.profile}>
                    <InputField
                      placeholder={"Postcode"}
                      value={loginDetails.address?.zip || ""}
                      setState={() => {}}
                      type="text"
                      itemKey="zip"
                      error={false}
                      handleUpdate={updateAddress}
                      darkMode={darkMode}
                      disabled={false}
                    />
                  </LoadingSkeleton>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center mt-12 mb-1">
            <div className="w-56">
              <LoadingSkeleton isLoading={loadingObj.profile} width="w-56" height="h-10" containerHeight="h-10" topPadding="mt-0">
                <SmallButtonYellow
                  text={"Opslaan"}
                  onClick={() => handleUpdate()}
                  disabled={checkDetailDifference()}
                  darkMode={darkMode}
                  loading={loadingUpdate} 
                />
              </LoadingSkeleton>
            </div>
          </div>
        </div>

        <hr className="my-12" />

        <div className="font-bold text-main-dark pt-2 text-lg">Voorkeuren voor berichten:</div>
        <div className="mt-4">Kies hoe je als eerste de beste deals en tips wilt ontvangen.</div>
        <div className="gap-y-4 mt-6 flex flex-col">
          <div className="flex flex-col gap-4">
            <LoadingSkeleton isLoading={loadingObj.profile} width="w-80" height="h-10" containerHeight="h-10" topPadding="mt-0">
              <Slider
                value={optIns.emailMarketing}
                onClick={() => updateOptIn(!optIns.emailMarketing, 'emailMarketing')}
                darkMode={darkMode}
                text={t("emailMarketing")}
              />
            </LoadingSkeleton>
            <LoadingSkeleton isLoading={loadingObj.profile} width="w-80" height="h-10" containerHeight="h-10" topPadding="mt-0">
              <Slider
                value={optIns.pushNotifications}
                onClick={() => updateOptIn(!optIns.pushNotifications, 'pushNotifications')}
                darkMode={darkMode}
                text={t("pushNotifications")}
              />
            </LoadingSkeleton>
          </div>
          <div className="flex justify-center items-center mt-12 mb-1">
            <div className="w-56">
              <LoadingSkeleton isLoading={loadingObj.profile} width="w-56" height="h-10" containerHeight="h-10" topPadding="mt-0">
                <SmallButtonYellow
                  text={"Opslaan"}
                  onClick={() => handleUpdateOptIn()}
                  disabled={compareOptIn()}
                  darkMode={darkMode}
                  loading={loadingOptIn}  
                />
              </LoadingSkeleton>
            </div>
          </div>
        </div>
      </div>
    </PrivateLayout>
  );  
}