import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

type LinkItemMyAccountProps = {
  icon: any; 
  text: string;
  link: string | string[]; 
  collapseSidebar: boolean;
  external: boolean;
  isMenuOpen?: boolean;
  setIsMenuOpen?: (isOpen: boolean) => void;
}

export const LinkItemMyAccount = ({ 
  icon, 
  text, 
  link, 
  collapseSidebar, 
  external, 
  isMenuOpen,
  setIsMenuOpen 
}: LinkItemMyAccountProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(false);

  useEffect(() => {
    setActiveLink(compareLink());
  }, [location.pathname, link]);

  const compareLink = () => {
    if (Array.isArray(link)) {
      return link.some(singleLink => location.pathname.startsWith(singleLink));
    }
    return location.pathname.startsWith(link);
  };

  const handleLinkClick = () => {
    if (!external) {
      navigate(Array.isArray(link) ? link[0] : link);
      if (setIsMenuOpen) {
        setIsMenuOpen(false);
      }
    } else {
      if (setIsMenuOpen) {
        setIsMenuOpen(false);
      }
      window.open(Array.isArray(link) ? link[0] : link, '_blank');
    }
  };

  return (
    <a 
      href={external ? (Array.isArray(link) ? link[0] : link) : undefined} 
      target={external ? "_blank" : undefined}
      rel={external ? "noreferrer" : undefined}
      className={` 
        flex items-center h-[62px] animate select-none cursor-pointer
        ${activeLink ? 'text-main-color border-main font-bold' : 'text-white hover:text-main-color transition-all duration-50'}  
        ${!isMenuOpen ? 'border-l-[8px] border-black' : 'border-black'} 
        ${isMenuOpen ? 'justify-start' : ''} 
      `}
      onClick={(e) => {
        e.preventDefault();
        handleLinkClick();
      }}
    >
      <div className={`${collapseSidebar || isMenuOpen ? "ml-4 w-6 h-6" : "ml-7 w-7 h-7"} flex-shrink-0 flex justify-center`}>
        {icon} 
      </div>
      {(collapseSidebar || isMenuOpen) && (
        <div className="ml-5 flex-1 text-left">
          {text} 
        </div>
      )}
    </a>
  );
};