import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App.tsx';

import './index.css';
import 'component-library/dist/style.css';
import './i18n';

import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/UserContext.tsx';
import { NotificationProvider } from './context/Notification/NotificationContext.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <UserProvider>
    <NotificationProvider>
      <BrowserRouter>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </NotificationProvider>
  </UserProvider>
);