type Deal = {
  id: number;
  external_id: string | null;
  external_psp_id: string | null;
  external_reservation_id: string | null;
  payment_provider_id: number;
  status: string;
  customer_id: number;
  created_at: string;
  updated_at: string;
  order_provider_type: string;
  product: {
    id: number;
    product_provider_id: number;
    order_provider_id: number;
    external_id: string | null;
    status: string;
    intro: string;
    description: string;
    disclaimer: string | null;
    discount_label: string;
    featured: boolean;
    cjp_budget: boolean;
    sort_order: number;
    name: string;
    slug: string;
    language: string;
    location_old: string | null;
    starts_at: string | null;
    ends_at: string | null;
    event_starts_at: string | null;
    event_ends_at: string | null;
    requires_date: boolean;
    requires_timeslot: boolean;
    external_url: string | null;
    confirmation_text: string | null;
    updated_at: string;
    location: string | { type: string; coordinates: number[] | null } | null;
    images: {
      mobile: string[];
      desktop: string[];
    };
    redirect_type: string;
  };
  lines: {
    id: number;
    external_id: string | null;
    product_variant_id: number;
    price: string;
    quantity: number;
    code: string | null;
    code_type: string | null;
    order_id: number;
    date: string;
    timeslot: string;
    created_at: string;
    updated_at: string;
    product_variant: {
      id: number;
      external_id: string | null;
      price: string;
      name: string;
      slug: string;
      intro: string | null;
      description: string | null;
      status: string;
      subject_line: string | null;
      starts_at: string | null;
      ends_at: string | null;
      external_url: string | null;
      product_id: number;
      referer_query: string | null;
      referer_content: string | null;
      requires_active_membership: boolean;
      product: {
        id: number;
        product_provider_id: number;
        order_provider_id: number;
        external_id: string | null;
        status: string;
        intro: string;
        description: string;
        disclaimer: string | null;
        discount_label: string;
        featured: boolean;
        cjp_budget: boolean;
        sort_order: number;
        name: string;
        slug: string;
        language: string;
        location_old: string | null;
        starts_at: string | null;
        ends_at: string | null;
        event_starts_at: string | null;
        event_ends_at: string | null;
        requires_date: boolean;
        requires_timeslot: boolean;
        external_url: string | null;
        confirmation_text: string | null;
        updated_at: string;
        location: string | { type: string; coordinates: number[] | null } | null;
        images: {
          mobile: string[];
          desktop: string[];
        };
        redirect_type: string;
      };
    };
  }[];
};

export const getDeals = (): Deal[] => {
  return [
    {
      "id": 284453,
      "external_id": "3550D34184F17C61813806BF92727AA5",
      "external_psp_id": "pid4918509315t",
      "external_reservation_id": "12193217",
      "payment_provider_id": 4,
      "status": "paid",
      "customer_id": 228617,
      "created_at": "2024-11-28T14:00:30.000000Z",
      "updated_at": "2024-11-28T14:00:34.000000Z",
      "product": {
        "id": 2007,
        "product_provider_id": 17,
        "order_provider_id": 13,
        "external_id": "811",
        "status": "published",
        "intro": "Dit is een testaccount van CJP in Global Reseller",
        "description": "<p>Hiermee testen we aanpassingen aan ticketverkoop etc.<\/p>",
        "disclaimer": "<p><em>Input indien gewenst van klant<\/em><\/p>\n\n<ul>\n\t<li><em>A<\/em><\/li>\n\t<li>B<\/li>\n\t<li>C<\/li>\n<\/ul>",
        "discount_label": "\u20ac 7,- korting",
        "featured": true,
        "cjp_budget": false,
        "sort_order": 2,
        "name": "CJP Museum ",
        "slug": "test-museum-datum-en-tijd",
        "language": "nl",
        "location_old": "(0,0)",
        "starts_at": "2023-04-18",
        "ends_at": "2025-05-31",
        "event_starts_at": null,
        "event_ends_at": null,
        "requires_date": true,
        "requires_timeslot": true,
        "external_url": null,
        "confirmation_text": null,
        "updated_at": "2024-11-28T02:00:44.000000Z",
        "location": {
          "type": "Point",
          "coordinates": [
            0,
            0
          ]
        },
        "images": {
          "mobile": [
            "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/5708\/responsive-images\/bO4IlQ3NNxFUvX6NCz90n1KUEru48i-metadXcxU2F6ekQ3Z1NZb0cyeVprZW1lSjNBbDg0ZGwzLW1ldGFWMlZpYzJsMFpTMXBkR1Z0SUNnMktTNXFjR2M9LV9fX3Jlc2l6ZWRfODA4XzgwOC5qcGc%3D-___jpg-resized_714_714.jpg"
          ],
          "desktop": [
            "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/5707\/responsive-images\/6CGuZ8Z2cDO7BtQCfuf5KCwe3JRQ5M-metadXcxU2F6ekQ3Z1NZb0cyeVprZW1lSjNBbDg0ZGwzLW1ldGFWMlZpYzJsMFpTMXBkR1Z0SUNnMktTNXFjR2M9LV9fX3Jlc2l6ZWRfODA4XzgwOC5qcGc%3D-___jpg-resized_808_808.jpg"
          ]
        },
        "redirect_type": "external"
      },
      "lines": [
        {
          "id": 272472,
          "external_id": "5566",
          "product_variant_id": 2127,
          "price": "12.50",
          "quantity": 1,
          "code": "771231231815133845",
          "code_type": "QR",
          "order_id": 284453,
          "date": "2024-11-29",
          "timeslot": "11:00",
          "created_at": "2024-11-28T14:00:30.000000Z",
          "updated_at": "2024-11-28T14:00:37.000000Z",
          "product_variant": {
            "id": 2127,
            "external_id": "5566",
            "price": "12.50",
            "name": "CJP Elke Dag Ticket",
            "slug": "cjp_museum_cjp_elke_dag_ticket",
            "intro": null,
            "description": null,
            "status": "published",
            "subject_line": null,
            "starts_at": "2024-03-01",
            "ends_at": null,
            "external_url": null,
            "product_id": 2007,
            "referer_query": null,
            "referer_content": null,
            "requires_active_membership": true,
            "product": {
              "id": 2007,
              "product_provider_id": 17,
              "order_provider_id": 13,
              "external_id": "811",
              "status": "published",
              "intro": "Dit is een testaccount van CJP in Global Reseller",
              "description": "<p>Hiermee testen we aanpassingen aan ticketverkoop etc.<\/p>",
              "disclaimer": "<p><em>Input indien gewenst van klant<\/em><\/p>\n\n<ul>\n\t<li><em>A<\/em><\/li>\n\t<li>B<\/li>\n\t<li>C<\/li>\n<\/ul>",
              "discount_label": "\u20ac 7,- korting",
              "featured": true,
              "cjp_budget": false,
              "sort_order": 2,
              "name": "CJP Museum ",
              "slug": "test-museum-datum-en-tijd",
              "language": "nl",
              "location_old": "(0,0)",
              "starts_at": "2023-04-18",
              "ends_at": "2025-05-31",
              "event_starts_at": null,
              "event_ends_at": null,
              "requires_date": true,
              "requires_timeslot": true,
              "external_url": null,
              "confirmation_text": null,
              "updated_at": "2024-11-28T02:00:44.000000Z",
              "location": {
                "type": "Point",
                "coordinates": [
                  0,
                  0
                ]
              },
              "images": {
                "mobile": [
                  "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/5708\/responsive-images\/bO4IlQ3NNxFUvX6NCz90n1KUEru48i-metadXcxU2F6ekQ3Z1NZb0cyeVprZW1lSjNBbDg0ZGwzLW1ldGFWMlZpYzJsMFpTMXBkR1Z0SUNnMktTNXFjR2M9LV9fX3Jlc2l6ZWRfODA4XzgwOC5qcGc%3D-___jpg-resized_714_714.jpg"
                ],
                "desktop": [
                  "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/5707\/responsive-images\/6CGuZ8Z2cDO7BtQCfuf5KCwe3JRQ5M-metadXcxU2F6ekQ3Z1NZb0cyeVprZW1lSjNBbDg0ZGwzLW1ldGFWMlZpYzJsMFpTMXBkR1Z0SUNnMktTNXFjR2M9LV9fX3Jlc2l6ZWRfODA4XzgwOC5qcGc%3D-___jpg-resized_808_808.jpg"
                ]
              },
              "redirect_type": "external"
            }
          }
        }
      ],
      "order_provider_type": "ticket"
    }
,
  {
    "id": 284455,
    "external_id": null,
    "external_psp_id": null,
    "external_reservation_id": null,
    "payment_provider_id": 1,
    "status": "paid",
    "customer_id": 228617,
    "created_at": "2024-11-28T14:33:23.000000Z",
    "updated_at": "2024-11-28T14:33:24.000000Z",
    "product": {
      "id": 878,
      "product_provider_id": 13,
      "order_provider_id": 4,
      "external_id": null,
      "status": "published",
      "intro": "Europa’s nummer 1 in retail voor beauty",
      "description": "<p><strong>Nu tot 25% korting&nbsp;op beauty bij&nbsp;<a href=\"https://www.awin1.com/cread.php?awinmid=15785&amp;awinaffid=941733&amp;ued=https%3A%2F%2Fwww.lookfantastic.nl%2Foffers%2Feu-exclusions%2Faffiliates%2Flanding-page.list\">LOOKFANTASTIC</a>. Plus&nbsp;5% extra op afgeprijsde producten. Klik op de blauwe knop voor jouw kortingscode!&nbsp;</strong></p>",
      "disclaimer": null,
      "discount_label": "Tot 25% korting",
      "featured": true,
      "cjp_budget": false,
      "sort_order": 97,
      "name": "LOOKFANTASTIC",
      "slug": "lookfantastic",
      "language": "nl",
      "location_old": null,
      "starts_at": null,
      "ends_at": null,
      "event_starts_at": null,
      "event_ends_at": null,
      "requires_date": false,
      "requires_timeslot": false,
      "external_url": null,
      "confirmation_text": "<p><strong><a href=\"http://www.awin1.com/cread.php?awinmid=15785&amp;awinaffid=941733&amp;ued=https%3A%2F%2Fwww.lookfantastic.nl%2Foffers%2Feu-exclusions%2Faffiliates%2Flanding-page.list\">Klik hier</a> voor de website van LOOKFANTASTIC.&nbsp;</strong><strong>Met deze kortingscode krijg je tot 25% korting + 5% extra op afgeprijsde producten.</strong></p>",
      "updated_at": "2023-12-21T16:54:35.000000Z",
      "location": "",
      "images": {
        "mobile": [],
        "desktop": []
      },
      "redirect_type": "internal"
    },
    "lines": [
      {
        "id": 272474,
        "external_id": "/lookfantastic/tickets/checkout-voucher/CJPLFEX",
        "product_variant_id": 2037,
        "price": "0.00",
        "quantity": 1,
        "code": null,
        "code_type": null,
        "order_id": 284455,
        "date": "2024-11-28",
        "timeslot": "15:33",
        "created_at": "2024-11-28T14:33:23.000000Z",
        "updated_at": "2024-11-28T14:33:23.000000Z",
        "product_variant": {
          "id": 2037,
          "external_id": null,
          "price": "0.00",
          "name": "LookFantastic Q2 2023",
          "slug": "lookfantastic_lookfantastic_q2_2023",
          "intro": null,
          "description": null,
          "status": "published",
          "subject_line": null,
          "starts_at": null,
          "ends_at": null,
          "external_url": null,
          "product_id": 878,
          "referer_query": null,
          "referer_content": null,
          "requires_active_membership": true,
          "product": {
            "id": 878,
            "product_provider_id": 13,
            "order_provider_id": 4,
            "external_id": null,
            "status": "published",
            "intro": "Europa\u2019s nummer 1 in retail voor beauty",
            "description": "<p><strong>Nu tot 25% korting&nbsp;op beauty bij&nbsp;<a href=\"https:\/\/www.awin1.com\/cread.php?awinmid=15785&amp;awinaffid=941733&amp;ued=https%3A%2F%2Fwww.lookfantastic.nl%2Foffers%2Feu-exclusions%2Faffiliates%2Flanding-page.list\">LOOKFANTASTIC<\/a>. Plus&nbsp;5% extra op afgeprijsde producten. Klik op de blauwe knop voor jouw kortingscode!&nbsp;<\/strong><\/p>\n\n<p>Zoek jij ook altijd&nbsp;de beste skincare routine en de beste&nbsp;make-up tutorials? Of volg je&nbsp;de curly girl methode? Dan hoeven we je niet meer te vertellen dat LOOKFANTASTIC d&eacute; plek is waar jij je beauty producten scoort. Hier vind je een assortiment van 22.000 producten, inclusief alle topmerken zoals Olaplex, Tangle Teezer, Versed, Shea Moisture, Tan-Luxe, The Inkey List en Weleda. Alles wat je nodig hebt (en vast meer) vind je <strong><a href=\"http:\/\/www.awin1.com\/cread.php?awinmid=15785&amp;awinaffid=941733&amp;ued=https%3A%2F%2Fwww.lookfantastic.nl%2Foffers%2Feu-exclusions%2Faffiliates%2Flanding-page.list\">hier<\/a><\/strong> bij LOOKFANTASTIC.&nbsp;<\/p>",
            "disclaimer": null,
            "discount_label": "Tot 25% korting",
            "featured": true,
            "cjp_budget": false,
            "sort_order": 97,
            "name": "LOOKFANTASTIC",
            "slug": "lookfantastic",
            "language": "nl",
            "location_old": null,
            "starts_at": null,
            "ends_at": null,
            "event_starts_at": null,
            "event_ends_at": null,
            "requires_date": false,
            "requires_timeslot": false,
            "external_url": null,
            "confirmation_text": "<p><strong><a href=\"http:\/\/www.awin1.com\/cread.php?awinmid=15785&amp;awinaffid=941733&amp;ued=https%3A%2F%2Fwww.lookfantastic.nl%2Foffers%2Feu-exclusions%2Faffiliates%2Flanding-page.list\">Klik hier<\/a> voor de website van LOOKFANTASTIC.&nbsp;<\/strong><strong>Met deze kortingscode krijg je tot 25% korting + 5% extra op afgeprijsde producten.<\/strong><\/p>",
            "updated_at": "2023-12-21T16:54:35.000000Z",
            "location": null,
            "images": {
              "mobile": [
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_714_714.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_597_597.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_499_499.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_418_418.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_349_349.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_292_292.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_370_370.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_309_309.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_259_259.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_216_216.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_181_181.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_151_151.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_127_127.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3001\/responsive-images\/3p8PqaAPgfkAjD0ULg2MfXw0sCKyCY-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_106_106.png"
              ],
              "desktop": [
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_808_808.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_676_676.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_565_565.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_473_473.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_395_395.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_331_331.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___jpg-resized_277_277.jpg",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_309_309.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_259_259.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_216_216.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_181_181.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_151_151.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_127_127.png",
                "https:\/\/storage.googleapis.com\/ticketbackend-acceptance\/3000\/responsive-images\/zc2SliLrEiptITD4pPCarv2BvR6QgU-metaQ0pQIDEwODAgeDEwODAucG5n-___media_library_original_106_106.png"
              ]
            },
            "redirect_type": "internal"
          }
        },
      }
    ],
    order_provider_type: "voucher"
  }
]
}
