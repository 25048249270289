import { cancelSubscriptionByUuid } from "@components/api/API";
import { SmallButtonYellow } from "component-library";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type PopupProps = {
  open: boolean;
  closePopup: () => void;
  subscription: any;
}

export const CancelSubscriptionPopup = ({ 
  open,
  closePopup,
  subscription,
}: PopupProps) => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  const [cancelSub, setCancelSub] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        closePopup();
        setCancelSub(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, closePopup]);

 
  if(!open) return null;

  const cancelSubscription = async() => {
    // let obj = {
    //   refundAmount: subscriptions?.refundAmount || 0
    // };
    // let res = await deleteSubscription(user.uuid, subscription.uuid, obj)
    // if(res.status === 200) { 
    //   closePopup()
    //   if(handleReloadData) handleReloadData()
    //   setCancelSub(false)
    //   notify("Lidmaatschap is succesvol opgezegd", "success")
    // } else {
    //   notify("Er is iets fout gegaan met het opzeggen.", "error")
    // }
  }

  const formatMoney = (amount: number) => {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(amount)
  }

  const handleClose = () => {
    closePopup()
    setCancelSub(false)
    // setRefundToggle(false)
  }

  const handleCancelSubscription = async() => {
    let res = await cancelSubscriptionByUuid(subscription.uuid)
    if(res.status === 200) { 
      closePopup()
      setCancelSub(false)
    }
  }

  return(
    <div className="fixed inset-0 z-[1000] w-screen h-screen grid place-content-center">
      <div className="absolute w-screen h-screen bg-black/40 z-[100] backdrop-blur">
        
      </div>
      <div className="max-w-[520px] mx-4 min-h-[250px] flex flex-col rounded-lg bg-white relative z-[110] p-6" ref={modalRef}>
        <div className="text-xl font-bold text-main-blue pb-3">
          {"Lidmaatschap opzeggen"}
        </div>
        <div className="flex-1">
          <div className="text-gray-600">
            Weet je zeker dat je dit lidmaatschap wilt opzeggen?
          </div>
          <div className="text-gray-600">
            Bij het opzeggen van je lidmaatschap zal er {formatMoney(subscription.payment?.amount||0)} worden geretourneerd, het lidmaatschap wordt aan het einde van de dag stop gezet.
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-x-5 mt-6 gap-y-4"> 
          <div className="flex-1">
            <SmallButtonYellow
              text={"Annuleren"}
              onClick={()=> handleClose()}
              disabled={false} 
              inverted={true}
            />
          </div>
          <div className="flex-1">
            <SmallButtonYellow
              text={"Bevestigen"}
              onClick={()=> handleCancelSubscription()}
              disabled={false} 
              inverted={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}