import { useUser } from '@context/UserContext';
import { XMarkIcon } from '@heroicons/react/16/solid';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type CJP_cardProps = {
  loadingObj: any;
  userProfile: any;
  activeSub: boolean;
  subscription: any;
  togglePopup: () => void;
  showCloseIcon?: boolean; 
}

export const CJP_card = ({ loadingObj, userProfile, subscription, togglePopup, showCloseIcon=true }: CJP_cardProps) => {
  const { activeSubscription } = useUser();
  const formatDate = (date: string) => {
    const [year, month, day] = date.split('T')[0].split('-');
    return `${day}-${month}-${year}`; 
  }

  const getImage = () => {
    if(subscription.cardFrontImageUrl.includes('docker')){
      return "http://mijn.dev-docker.com/cards/student-mbo-2024.png"
    }
    else return subscription.cardFrontImageUrl
  }

  const handleClickOutside = () => {
    // trigger toggle popup when user clicks outside of the card
    togglePopup()
  }

  return(
    <div className="relative" >
      {loadingObj.profile ? (
        <div className="space-y-4">
          <Skeleton height={350} width={350} />
          <Skeleton height={40} width={320} />
          <Skeleton height={40} width={320} />
          <Skeleton height={40} width={320} />
          <Skeleton height={40} width={320} />
        </div>
      ) : (
        <div className="pt-4 relative select-noe">
          <div className="rounded-lg border-2 border-white overflow-hidden h-fit w-full">
            <img src={getImage()} alt="gratisImg" className="w-[350px] aspect-[3/5]" />
          </div>
          {showCloseIcon && (
            <div className="absolute top-7 right-2 text-white text-2xl font-bold cursor-pointer" onClick={handleClickOutside}>
              <XMarkIcon className="w-6 h-6" />
            </div>
          )}
          <div className="absolute w-full text-center top-[35%] text-4xl text-white font-bold">
            <div className="text-6xl">
              {userProfile.firstName || ""} 
            </div>
            <div>
              {userProfile.lastName || ""}
            </div>
          </div>
          <div className="absolute w-full px-8 text-lg bottom-[15%] text-white">
            {activeSubscription.school?.name && <div className='font-bold'> {activeSubscription.school?.name || ""}</div>}
            <div>
              Geboortedatum: <span className="font-bold">{userProfile.dateBirth}</span>
            </div>
            <div>
              CJP nr: <span className="font-bold">{subscription.code}</span>
            </div>
            <div>
              Geldig tot: <span className="font-bold">{formatDate(subscription.endDate)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}