import React, { useState } from "react";
import { SmallButtonYellow } from "component-library";
import logo from "@assets/logo.svg";

interface CardProps {
  id: number;
  title: string;
  purchasedDate?: string;
  image: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  cjpNumber?: string;
  activationCode?: string;
  autoRenew?: string;
  onClick?: () => void;
  buttonText: string;
}

export const DealsCard: React.FC<CardProps> = ({
  id,
  title,
  purchasedDate,
  image,
  startDate,
  endDate,
  cjpNumber,
  activationCode,
  autoRenew,
  status,
  onClick,
  buttonText,
}) => {
  const [imgError, setImgError] = useState(false);

  const getStatusLabel = (status: string = "") =>
    status === "active" ? "Actief" : "Afgelopen";
  
  const getStatusColor = (status: string = "") =>
    status === "active" ? "text-green-600" : "text-red-600";

  const formatDate = (date: string) => {
    if (!date) return "—";
    const [year, month, day] = date.split("T")[0].split("-");
    return `${day}-${month}-${year}`;
  };

  const hasDealId = id && id !== 0;

  const dataFields = [
    { label: "Naam", value: title },
    hasDealId ? { label: "Deal ID", value: id } : null,
    hasDealId ? null : { label: "Status", value: getStatusLabel(status), customClass: getStatusColor(status) },
    { label: "Besteldatum", value: purchasedDate || "" },
    { label: "CJP-nummer", value: cjpNumber || "—" },
    { label: "Activatiecode", value: activationCode || "—" },
    { label: "Startdatum", value: formatDate(startDate || "") },
    { label: "Einddatum", value: formatDate(endDate || "") },
    hasDealId ? null : { label: "Automatish verlengen", value: autoRenew ? "Ingeschakeld" : "Uitgeschakeld" },
    { label: "Uitgekeerde restitutie", value: "—" },
  ].filter(Boolean);

  return (
    <div className="flex flex-col w-full bg-gray-100 p-6 rounded-lg justify-between">
      <div className="md:flex md:space-x-4 items-center">
        <div className="md:w-2/5 flex justify-center items-center rounded-lg overflow-hidden relative h-60 md:h-full">
          {imgError || !image ? (
            <div className="h-full w-full flex justify-center items-center bg-gray-200">
              <img
                src={logo}
                alt="logo"
                className="h-20 w-20 object-contain"
              />
            </div>
          ) : (
            <img
              src={image}
              alt={title}
              className="w-full h-50 -mt-8 object-cover rounded-lg"
              onError={() => setImgError(true)}
            />
          )}
        </div>

        <div className="flex flex-col w-full gap-y-1 mt-4 md:mt-0"> 
          {dataFields.filter(field => field !== null).map(({ label, value, customClass }) => (
            value && value !== "—" && (
              <div key={label} className="flex text-sm text-gray-600 md:items-center">
                <p className="w-3/6 text-xs mr-1">{label}:</p>
                <p className={`w-3/6 ${customClass || "text-black"}`}>{value}</p>
              </div>
            )
          ))}
        </div>
      </div>

      <div className="mt-5 w-full mx-auto md:flex gap-4 justify-center"> 
        <div className="mt-4 md:mt-0">
          <SmallButtonYellow
            text={buttonText}
            onClick={()=>onClick && onClick()}
            disabled={false}
            />
        </div>
      </div>
    </div>
  );
};