import { useState } from "react";
import PointerImg from '@assets/PointerImg.png'
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";

type ChooseCJPModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const RequestVO = ({ openModal, toggleModal, showCloseButton = false, darkMode = false }: ChooseCJPModalProps) => {
  const { t } = useTranslation()
  const customNavigate = useCustomNavigate();

  return (
    <div className="w-full mt-8">
      <div className="flex justify-between items-start w-full">
        <div className="flex flex-col">
          <div className="pt-1 text-black/80"> 
            <div className="flex flex-col">
              <div className="font-bold font-formula tracking-wide mb-2 text-2xl">Vraag Jouw CKV-docent.</div>
              <div className="font-bold"> Doet jouw school mee met de Cultuurkaart?</div>
              <p>Dan heb je mogelijk recht op een gratis CJP-lidmaatschap. Vraag het na bij je CKV-docent.</p>
            </div>
              
            <div className="flex mt-6 gap-x-2">
              <img 
                src={PointerImg}
                className="w-4 h-4 mt-1"
              />
              {t('freeForVOPerk')}
            </div>
            <div className="flex gap-x-2">
              <img 
                src={PointerImg}
                className="w-4 h-4 mt-1"
              />
              {t('continueWithoutCjpMembership')}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row mt-6 justify-center"> 
        <div className="text-center cursor-pointer underline" onClick={()=>customNavigate('/account')}>
          {t('continueWithout')}
        </div>
      </div>
    </div>
  )
}
